import { EventEmitter, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Widget } from 'src/app/components/model/Widget';
import { WidgetComponent } from '../widget/widget.component';

export class DynamicMenu {
  title: string;
  icon: string;
  badge: string;
  closeable: boolean;
  $close = new EventEmitter<Event>();

  private navigations: Widget[] = [];
  $currentNavigation: BehaviorSubject<Widget> = new BehaviorSubject(<Widget>{});

  constructor(title: string, icon: string, component?: Type<WidgetComponent>, data?: any, isCloseable?: boolean) {
    this.title = title;
    this.icon = icon;
    this.badge = '';
    this.closeable = isCloseable || false;
    if (component) this.setNavigation(component, data);
  }

  public setNavigation(component: Type<WidgetComponent>, data?: unknown): void {
    const widget = new Widget(component, data);
    this.navigations.push(widget);
    this.$currentNavigation.next(widget);
  }

  public setForcedNavigation(component: Type<WidgetComponent>, data?: unknown) {
    const widget = new Widget(component, data);
    this.navigations.unshift(widget);
  }

  public setBadge(badge: string): void {
    this.badge = badge;
  }

  public backNavigation(): unknown {
    if (!this.hasBack()) {
      return;
    }

    this.navigations.pop();
    const navigation = this.navigations[this.navigations.length - 1];

    this.$currentNavigation.next(navigation);
    return navigation;
  }

  public reset(): void {
    const navigation = this.navigations[0];
    this.navigations = [navigation];
    this.$currentNavigation.next(navigation);
  }

  public hasBack(): boolean {
    return this.navigations.length > 1;
  }

  public isDetached(): boolean {
    return false;
  }

  public close() {
    this.$close.emit();
  }
}
