import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { YaIconsService } from './icon.service';

@Component({
  selector: 'ya-icon',
  styleUrls: ['./icon.component.scss'],
  template: `
    <ng-content *ngIf="!iconSRC"></ng-content>
    <svg
      *ngIf="iconSRC"
      attr.width="{{ size }}px"
      attr.height="{{ size }}px"
      attr.fill="{{ fill }}"
      attr.stroke="{{ fill }}"
      attr.stroke-width="{{ strokeWidth }}"
      [innerHTML]="iconSRC"
    >
</svg>
  `,
  host: {
    '[class.hidden]': '!iconSRC',
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements AfterViewInit {
  protected iconName: string = '';
  protected iconSRC;

  @Input() icon: string = '';
  @Input() size = 20;
  @Input() fill = 'currentColor';
  @Input() strokeWidth = 1.1;

  constructor(
    private _element: ElementRef,
    private cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private readonly yaIconsService: YaIconsService
  ) { }

  async ngAfterViewInit() {
    if (this.icon) {
      this.iconName = this.icon;
      return;
    }

    const nativeElement = this._element.nativeElement;
    if (!nativeElement.innerText) {
      return
    }

    this.iconName = nativeElement.innerText.trim();

    const iconSRC = this.yaIconsService.icons[this.iconName];
    this.iconSRC = this.sanitizer.bypassSecurityTrustHtml(iconSRC);

    this.cdRef.detectChanges();

  }
}
