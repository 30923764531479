import { trigger, animate, style, transition, query, stagger } from '@angular/animations';

export function fadeInAnimation(name: string = 'fadeIn', duration: number = 100, delay = 0) {
  return trigger(name, [
    transition('void => *', [style({ opacity: 0 }), animate(`${duration}ms {{delay}}ms`, style({ opacity: 1 }))], {
      params: {
        delay,
      },
    }),
  ]);
}

export function fadeOutAnimation(name: string = 'fadeOut', duration: number = 100, delay = 0) {
  return trigger(name, [
    transition('* => void', [style({ opacity: 0 }), animate(`${duration}ms {{delay}}ms`, style({ opacity: 1 }))], {
      params: {
        delay,
      },
    }),
  ]);
}

export function fadeInOutAnimation(name: string = 'fadeInOut', duration: number = 100, delay = 0) {
  return trigger(name, [
    transition('void => *', [style({ opacity: 0 }), animate(`${duration}ms {{delay}}ms`, style({ opacity: 1 }))], {
      params: {
        delay,
      },
    }),
    transition('* => void', [style({ opacity: 1 }), animate(`${duration}ms {{delay}}ms`, style({ opacity: 0 }))], {
      params: {
        delay,
      },
    }),
  ]);
}

export function staggerFade(name: string = 'fade', duration: number = 500, inDuration = 100, outDuration = 100) {
  return trigger(name, [
    transition('* => *', [
      // each time the binding value changes
      query(':leave', [stagger(outDuration, [animate(duration, style({ opacity: 0 }))])], { optional: true }),
      query(':enter', [style({ opacity: 0 }), stagger(inDuration, [animate(duration, style({ opacity: 1 }))])], {
        optional: true,
      }),
    ]),
  ]);
}
