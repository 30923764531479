import { Component, Input } from '@angular/core';

export enum Appearance {
  NORMAL = 'normal',
  FAT = 'fat',
  LIGHT = 'light',
}

export enum Color {
  dark = 'dark',
  red = 'red',
  green = 'green',
  yellow = 'yellow',
  orange = 'orange',
  transparent = 'transparent',
  bright = 'bright',
  brightRed = 'bright-red',
}

export enum Mode {
  row = 'row',
  column = 'column',
}
@Component({
  selector: 'ya-button',
  template: `
    <button mat-button [ngClass]="getClasses()" [class.loading]="loading" [disableRipple]="disableRipple">
      <ya-ripple *ngIf="loading" [color]="loadingColor"></ya-ripple>
      <ng-content *ngIf="!loading"></ng-content>
    </button>
  `,
  styleUrls: ['./button.component.scss'],
  host: {
    '[class.disabled]': 'disabled',
    '[class.loading]': 'loading',
  },
})
export class ButtonComponent {
  @Input() appearance: string | Appearance = Appearance.NORMAL;
  @Input() color: string | Color = Color.dark;
  @Input() highlighted: string | boolean = false;
  @Input() highlighted4px: string | boolean = false;
  @Input() highlightedRed: string | boolean = false;
  @Input() disabled: boolean = false;
  @Input() disableRipple: boolean = false;
  @Input() loading: boolean = false;
  @Input() loadingColor: string = 'white';

  getClasses() {
    return {
      [this.appearance]: true,
      [this.color]: true,
      highlighted: this.highlighted,
      highlighted4px: this.highlighted4px,
      highlightedRed: this.highlightedRed,
      rippleDisabled: this.disableRipple,
    };
  }
}

@Component({
  selector: `ya-button-group`,
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  host: {
    '[class]': 'mode',
  },
})
export class ButtonGroupComponent {
  @Input() mode: Mode | string = Mode.row;
}
