import { Injectable } from "@angular/core";
import { yaIcons } from './ya-icons';

export interface YaIcon {
  [name: string]: string
}
@Injectable({
  providedIn: 'root'
})
export class YaIconsService {
  icons: YaIcon;

  constructor() {
    console.log('YA Icons loading');
    this.icons = yaIcons;
  }

}
