<ya-expansion-panel [collapsed]="true" *ngFor="let test of tests" [collapsible]="test.description">
  <ya-expansion-panel-title>
    {{ test.name }}
  </ya-expansion-panel-title>
  <ya-expansion-panel-subtitle>
    <div class="status success" *ngIf="test.status == ETestStatus.PASS">
      <ya-icon>ya-editing-check-mark</ya-icon>
      PASS
    </div>
    <div class="status danger" *ngIf="test.status == ETestStatus.FAIL">
      <ya-icon>ya-editing-remove</ya-icon>
      FAIL
    </div>
    <div class="status warning" *ngIf="test.status == ETestStatus.NA">
      <ya-icon>ya-alerts-feedback-warning</ya-icon>
      N/A
    </div>

  </ya-expansion-panel-subtitle>

  <ya-expansion-panel-body>
    {{ test.description }}
    <pre *ngFor="let detail of test.details">
      {{detail}}
    </pre>
  </ya-expansion-panel-body>
</ya-expansion-panel>
