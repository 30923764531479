import { Injectable } from "@angular/core";

const PROXY_API_PATH = '/light/api/dynamic-proxy';
export interface IProxyWMS {
  originalURL: string;
}

@Injectable({
  providedIn: 'root'
})
export class DynamicProxyService {

  convertURLToWMSProxyServer(url: string) {

    const [original_origin_and_pathname, search] = url.split('?');

    return `${PROXY_API_PATH}/wms?${search}&ORIGINAL_ORIGIN_AND_PATHNAME=${original_origin_and_pathname}`;
  }

}
