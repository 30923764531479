import { Subject } from 'rxjs';

export interface WorkerEvent {
  event: string;
  data: any;
}

export class WorkerEvents {
  private destroy$ = new Subject<void>();
  private event$ = new Subject<WorkerEvent>();

  private onMessage;

  constructor(private worker: Worker) {
    this.onMessage = this.handleMessage.bind(this);
    this.worker.addEventListener('message', this.onMessage);
  }

  events() {
    return this.event$.asObservable();
  }

  private handleMessage(message: MessageEvent<any>) {
    if (message.data?.type) this.event$.next(message.data);
  }

  destroy() {
    this.destroy$.next();
    this.worker.removeEventListener('message', this.onMessage);
  }
}
