import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OWSLayer } from '../../domain';
import { DynamicProxyService } from '../../utils/dynamic-proxy.service';

import proj4 from 'proj4';

export type GetCapabilitiesResultType = { result: string; layers: OWSLayer[]; url?: string };

@Injectable()
export class OWSService {
  private readonly BASE_PATH = '/light/api/ows';

  constructor(private readonly http: HttpClient, private readonly dps: DynamicProxyService) {}

  getCapabilities(url: string): Observable<GetCapabilitiesResultType> {
    return this.http.post<GetCapabilitiesResultType>(`${this.BASE_PATH}/get-capabilities`, { url });
  }

  requestGetFeatureInfo(requestURL: string): Observable<any> {
    return this.http.get<any>(requestURL);
  }

  convertURLGetMapToGetFeatureInfo(layerOMSURL: string, coordinates: number[]): string {
    const urlOMS = new URL(layerOMSURL);
    if (!urlOMS.searchParams.has('QUERY_LAYERS')) {
      const layers = urlOMS.searchParams.get('LAYERS');
      urlOMS.searchParams.set('QUERY_LAYERS', layers);
    }

    urlOMS.searchParams.set('REQUEST', 'GetFeatureInfo');
    urlOMS.searchParams.set('I', '50');
    urlOMS.searchParams.set('J', '50');
    urlOMS.searchParams.set('BBOX', this.createBBoxForCoordinates(coordinates));
    urlOMS.searchParams.set('INFO_FORMAT', 'application/json');
    const requiredParams = new Set([
      'SERVICE',
      'VERSION',
      'REQUEST',
      'SRS',
      'CRS',
      'WIDTH',
      'HEIGHT',
      'LAYERS',
      'QUERY_LAYERS',
      'I',
      'J',
      'BBOX',
      'INFO_FORMAT',
    ]);
    Array.from(urlOMS.searchParams.keys()).forEach((param) => {
      if (!requiredParams.has(param)) {
        urlOMS.searchParams.delete(param);
      }
    });

    return this.dps.convertURLToWMSProxyServer(urlOMS.toString());
  }

  createBBoxForCoordinates(coordinates: number[]): string {
    const coordinatesEPSG3857 = proj4('EPSG:4326', 'EPSG:3857', [coordinates[1], coordinates[0]]);
    const lat = coordinatesEPSG3857[0];
    const lng = coordinatesEPSG3857[1];
    const bbox = [lng - 0.1, lat - 0.1, lng + 0.1, lat + 0.1];
    return bbox.join(',');
  }
}
