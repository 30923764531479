import { Pipe, PipeTransform } from '@angular/core';
import { DistanceUnitType } from '../core/utils/global-units.service';

@Pipe({
  name: 'transformDistance',
})
export class TransformDistancePipe implements PipeTransform {
  transform(value: number | string, originUnit: DistanceUnitType, targetUnit: DistanceUnitType, decimalPlaces?: number) {
    value = Number(value);
    if (isNaN(value)) return undefined;
    const conversionTable = {
      nm: 1,
      meter: 1852,
      miles: 1.15078,
      km: 1.852,
    };
    const conversion = (value / conversionTable[originUnit]) * conversionTable[targetUnit];
    const result = Number(conversion) || value;

    if(decimalPlaces !== undefined) {
      return Number(result.toFixed(decimalPlaces));
    }
    
    return result
  }
}
