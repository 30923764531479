import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { ITask } from "../../domain";
import { TodoSearchParams } from "src/app/widgets/todo/todo.service.util";


export type TodoQuery = {
  page: number;
  take: number;
} & TodoSearchParams

@Injectable()
export class TodoService {

  private readonly BASE_PATH = '/light/api/todo';

  constructor(
    private readonly http: HttpClient
  ) { }


  list(query: TodoQuery): Promise<ITask[]> {
    const queryParams = [];

    for(const k of Object.keys(query)) {
      const v = query[k];
      if(v) {
        if(v instanceof Date) {
          queryParams.push(`${k}=${v.getTime()}`);
        } else {
          queryParams.push(`${k}=${v}`);
        }
      }
    }
    return lastValueFrom(this.http.get<ITask[]>(`${this.BASE_PATH}/list?${queryParams.join('&')}`));
  }

  async save(task: ITask): Promise<ITask> {
    if (task._id) {
      return this.update(task);
    } else {
      return this.create(task);
    }
  }

  create(task: ITask): Promise<ITask> {
    return lastValueFrom(this.http.post<ITask>(`${this.BASE_PATH}`, task));
  }

  update(task: ITask): Promise<ITask> {
    return lastValueFrom(this.http.put<ITask>(`${this.BASE_PATH}/${task._id}`, task));
  }

  getById(taskId: string): Promise<ITask> {
    return lastValueFrom(this.http.get<ITask>(`${this.BASE_PATH}/${taskId}`));
  }

  delete(task: ITask): Promise<boolean> {
    return lastValueFrom(this.http.delete<boolean>(`${this.BASE_PATH}/${task._id}`));
  }

}
