import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as Domain from '../../domain';

@Injectable({
  providedIn: 'root',
})
export class GroupApiService {
  constructor(private http: HttpClient) {}

  private readonly group_api_path = '/light/api/group';

  listAllGroups(): Observable<Domain.Group[]> {
    return this.http.get<Domain.RequestResponse>(this.group_api_path).pipe(map((res) => res.data as Domain.Group[]));
  }

  listGroupStatus(level?: string, groupId?: string): Observable<Domain.Group[]> {
    let headers = new HttpHeaders();
    if (level) headers = headers.append('level', level);

    return this.http
      .get<Domain.RequestResponse>(`${this.group_api_path}/status`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Group[]));
  }

}
