import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationActionType, NotificationFilter, NotificationObjectType } from '../../domain';
import * as Domain from '../../domain';
import { Observable, map } from 'rxjs';
import { api_default_limit, api_default_page } from '../api.constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  constructor(private http: HttpClient) {}

  listNotifications(options?: {
    objectTypes?: NotificationObjectType[];
    actionTypes?: NotificationActionType[];
    activeLayers?: string[];
    filter?: NotificationFilter;
    limit?: number;
    page?: number;
    cluster?: string
  }): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({
      page: options?.page ? String(options.page) : String(api_default_limit),
      limit: options?.limit ? String(options.limit) : String(api_default_page),
      show: options?.filter ? options.filter : Domain.NotificationFilter.NotHidden,
    });
    if (options?.objectTypes) {
      headers = headers.append('objectTypes', options.objectTypes);
    }
    if (options?.actionTypes) {
      headers = headers.append('actionTypes', options.actionTypes);
    }
    if (options?.activeLayers) {
      headers = headers.append('activeLayers', options.activeLayers);
    }
    if (options?.cluster) {
      headers = headers.append('cluster', options.cluster);
    }

    return this.http.get<Domain.RequestResponse>(`/light/api/Notification`, { headers: headers });
  }

  hideNotification(id: string): Observable<Domain.Notification> {
    const headers = new HttpHeaders({ id: id });
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/Notification/hide`, null, { headers: headers })
      .pipe(map((res) => res.data as Domain.Notification));
  }

  hideAllNotifications(ids?: string[]): Observable<number> {
    let headers = new HttpHeaders({});
    if (ids) {
      headers = headers.append('Notification_ids', ids);
    }
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/notification/hideAll`, { headers: headers })
      .pipe(map((res) => res.data as number));
  }

  deleteNotification(id: string): Observable<Domain.Notification> {
    const headers = new HttpHeaders({ id: id });
    return this.http
      .delete<Domain.RequestResponse>(`/light/api/Notification`, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.Notification));
  }
}
