<ya-menu>
  <ya-menu-title>
    Debug console
  </ya-menu-title>
  <ya-menu-body>

    <p>1. Please provide the validation code from the administrator.</p>

    <mat-form-field appearance="outline">
      <mat-label>Validation code</mat-label>
      <input matInput placeholder="code" [(ngModel)]="validationCode" />
    </mat-form-field>

    <p>2. Validate </p>
  </ya-menu-body>
  <ya-menu-footer>

    <ya-button [disabled]="!isValid()" (click)="start()"> Validate</ya-button>
  </ya-menu-footer>
</ya-menu>
