import { ErrorHandler, Injectable } from '@angular/core';
import { FeatureFlags, FeatureFlagsService } from 'src/app/core/utils/feature-flag.service';

export enum ELogType {
  LOG = 'LOG',
  WARN = 'WARN',
  ERROR = 'ERROR',
}
export enum ECategory {
  LOG = 'LOG',
  NETWORK = 'NETWORK',
  SOCKET = 'SOCKET',
  INDEXEDDB = 'INDEXEDDB',
  API = 'API',
  MAP = 'MAP',
  APP = 'APP',
}
export interface Log {
  type: ELogType;
  category: string;
  timestamp: Date;
  args: any;
}

export enum ETestStatus {
  PASS = 'pass',
  FAIL = 'fail',
  NA = 'NA',
}
export interface Test {
  status: ETestStatus;
  name: string;
  description?: string;
  details?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class DebugCollectorService implements ErrorHandler {
  _log = console.log;
  _error = console.error;
  _warn = console.warn;

  collector: Log[] = [];
  tests: Test[] = [];

  constructor(private featureFlagsService: FeatureFlagsService) {
    if (this.featureFlagsService.isFeatureEnabled(FeatureFlags.DebugConsoleFlag)) {
      console.log = (...args: any) => this.log(args);
      console.error = (...args: any) => this.error(args);
      console.warn = (...args: any) => this.warn(args);

      console.log(navigator.userAgent);

      if (navigator && (<any>navigator).connection) {
        (<any>navigator).connection.onchange = (e) => {
          const { downlink, effectiveType, rtt } = e.target;
          this.log({ downlink, effectiveType, rtt }, ECategory.NETWORK);
        };
      }
    }
  }

  log(args: any, category: ECategory = ECategory.LOG): void {
    this.addToCollector(ELogType.LOG, args, category);
    this._log.apply(console, args);
  }
  error(args: any, category: ECategory = ECategory.LOG): void {
    if (Array.isArray(args)) {
      args = args.map((entry) => entry.stack || entry.args || entry);
    }
    this.addToCollector(ELogType.ERROR, args, category);
    this._error.apply(console, args);
  }
  warn(args: any, category: ECategory = ECategory.LOG): void {
    this.addToCollector(ELogType.WARN, args, category);
    this._warn.apply(console, args);
  }

  private addToCollector(type: ELogType, args: any, category: ECategory = ECategory.LOG): void {
    const currDate = new Date();
    this.collector.push({
      type,
      category,
      timestamp: currDate,
      args: Array.isArray(args)
        ? args.map((arg) => (typeof arg === 'object' ? this.stringify(this.pruneObjectDepth(arg, 1)) : arg)).join('\n')
        : args,
    });
  }

  /*
   * Some objects are too big, we need limit the depth before add it to collector
   */
  private pruneObjectDepth(obj: any, depth = 3) {
    if (Array.isArray(obj) && obj.length > 0) {
      return depth === 0 ? ['???'] : obj.map((e) => this.pruneObjectDepth(e, depth - 1));
    } else if (obj && typeof obj === 'object' && Object.keys(obj).length > 0) {
      return depth === 0
        ? { '???': '' }
        : Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: this.pruneObjectDepth(obj[key], depth - 1) }), {});
    } else {
      return obj;
    }
  }

  /*
   * JSON.stringify alternative that works with circular structure
   * a = { c: 2}
   * a.b = a
   */
  private stringify(obj: any) {
    let cache = [];
    const str = JSON.stringify(
      obj,
      function (_key, value) {
        if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) {
            // Circular reference found, discard key
            return;
          }
          // Store value in our collection
          cache.push(value);
        }
        return value;
      },
      2,
    );
    cache = null; // reset the cache
    return str;
  }

  handleError(error: any): void {
    console.error(error);
  }

  getLogs(category: ECategory | null = null): Log[] {
    return this.collector.filter((c) => c.type === ELogType.LOG && (!category || c.category === category));
  }

  getWarn(category: ECategory | null = null): Log[] {
    return this.collector.filter((c) => c.type === ELogType.WARN && (!category || c.category === category));
  }

  getErrors(category: ECategory | null = null): Log[] {
    return this.collector.filter((c) => c.type === ELogType.ERROR && (!category || c.category === category));
  }

  getByCategory(category: ECategory): Log[] {
    return this.collector.filter((c) => c.category === category);
  }

  public async runTests(): Promise<Test[]> {
    this.tests = [];

    this.checkLocalStorageSupport();
    await this.checkIndexedDBSupport();
    this.checkNetwork();
    this.checkApiCommunication();
    this.checkWebsocket();
    this.checkApplicationLogs();
    this.checkApplicationWarnings();
    this.checkApplicationErrors();
    this.checkMapErrors();
    this.checkAppErrors();

    return this.tests;
  }

  private checkLocalStorageSupport(): void {
    const support = typeof Storage;
    let description = '';
    if (!support) {
      description = `
        LocalStorage and SessionStorage aren't available on the browser
      `;
    } else if (localStorage.length === 0) {
      description = `
        LocalStorage is supported but is empty
      `;
    }

    this.tests.push({
      name: 'Browser supports LocalStorage and SessionStorage',
      status: support ? ETestStatus.PASS : ETestStatus.FAIL,
      description,
    });
  }

  private async checkIndexedDBSupport() {
    const support = window.indexedDB;
    let description = '';
    if (!support) {
      description = `
        IndexedDB is not available on the browser
      `;
    } else {
      const dbs = await indexedDB.databases();
      if (dbs.length === 0) {
        description = `
          IndexedDB is available but is empty
        `;
      }
    }

    this.tests.push({
      name: 'Browser supports IndexedDB',
      status: support ? ETestStatus.PASS : ETestStatus.FAIL,
      description,
    });
  }

  private checkWebsocket(): void {
    const errors = this.getErrors(ECategory.SOCKET);
    const allLogs = this.getByCategory(ECategory.SOCKET);

    let description = '';
    if (errors.length > 0) {
      description = `
        Websocket encountered ${errors.length} errors during its operation.
      `;
    } else if (allLogs.length == 0) {
      description = `
        Websocket is not connected to the server.
      `;
    }

    this.tests.push({
      name: 'Websocket communication',
      status: errors.length == 0 && allLogs.length > 0 ? ETestStatus.PASS : ETestStatus.FAIL,
      description,
    });
  }

  private checkNetwork(): void {
    const logs = this.getLogs(ECategory.NETWORK);
    let description = '';
    if (logs.length > 0) {
      if (logs.find((l) => l.args.downlink === 0)) {
        description = `
          Network has lost connection during its operation
      `;
      } else {
        description = `
          Network has faced some downlink changes during its operation
      `;
      }
    }

    this.tests.push({
      name: 'Network activity',
      status: logs.length === 0 ? ETestStatus.PASS : ETestStatus.FAIL,
      description,
    });
  }

  private checkApiCommunication(): void {
    const apiErrors = this.getErrors(ECategory.API);

    let description = '';
    if (apiErrors.length > 0) {
      description = `
          API communication has faced ${apiErrors.length} issues during its operation.
      `;
    }

    this.tests.push({
      name: 'API communication',
      status: apiErrors.length === 0 ? ETestStatus.PASS : ETestStatus.FAIL,
      description,
    });
  }

  private checkApplicationLogs(): void {
    const logs = this.getLogs();
    this.tests.push({
      name: 'Application logs',
      status: logs.length == 0 ? ETestStatus.FAIL : ETestStatus.PASS,
    });
  }

  private checkApplicationWarnings(): void {
    const warnings = this.getWarn();
    this.tests.push({
      name: 'Application warnings',
      status: warnings.length > 0 ? ETestStatus.FAIL : ETestStatus.PASS,
    });
  }

  private checkApplicationErrors(): void {
    const errors = this.getErrors();
    this.tests.push({
      name: 'Application errors',
      status: errors.length > 0 ? ETestStatus.FAIL : ETestStatus.PASS,
    });
  }

  private checkMapErrors(): void {
    const errors = this.getByCategory(ECategory.MAP);

    let description = '';
    if (errors.length > 0) {
      description = `
          MAP has faced ${errors.length} issues during its operation.
      `;
    }

    this.tests.push({
      name: 'MAP health',
      status: errors.length === 0 ? ETestStatus.PASS : ETestStatus.FAIL,
      description,
    });
  }

  private checkAppErrors(): void {
    const errors = this.getByCategory(ECategory.APP);

    let description = '';
    if (errors.length > 0) {
      description = `
          App has faced ${errors.length} issues during its operation.
      `;
    }

    this.tests.push({
      name: 'App health',
      status: errors.length === 0 ? ETestStatus.PASS : ETestStatus.FAIL,
      description,
    });
  }

  public async downloadReport() {
    const results = {
      tests: await this.runTests(),
      logs: this.collector,
    };
    const data = JSON.stringify(results, null, 2);
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/json;charset=UTF-8,' + encodeURIComponent(data));
    element.setAttribute('download', `troubleshoot-${new Date().toISOString()}.json`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
