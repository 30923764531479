import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import * as Domain from '../../domain';
import { SituationEnvironmentEnum, SituationStateType } from '../../domain';
import { api_default_limit, api_default_page } from '../api.constants';

@Injectable({
  providedIn: 'root',
})
export class SituationApiService {
  constructor(private http: HttpClient) {}

  private readonly situation_api_path = '/light/api/Situation';

  listOperationalSituations(
    limit: number = api_default_limit,
    page: number = api_default_page,
  ): Observable<Domain.Situation[]> {
    const headers = new HttpHeaders({ page: String(page), limit: String(limit) });
    return this.http
      .get<Domain.RequestResponse>(`${this.situation_api_path}`, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.Situation[]));
  }

  listSituations({
    desc,
    offset = 0,
    limit,
    query,
    page = api_default_page,
    orderBy = 'Priority',
    folderId,
    haveAFolder,
    ignoreFolders,
    isDefault,
    environment,
    stateType,
    myCenter,
    myUser,
    listOnlyActive,
    listOnlyArchived,
    groupByArchived,
    year,
    month,
    ignoreDefaultSort,
  }: {
    desc?: boolean;
    offset?: number;
    limit?: number;
    query?: string;
    page?: number;
    orderBy?: string;
    folderId?: string;
    haveAFolder?: boolean;
    ignoreFolders?: boolean;
    isDefault?: boolean;
    environment?: SituationEnvironmentEnum;
    stateType?: SituationStateType;
    myCenter?: boolean;
    myUser?: boolean;
    listOnlyActive?: boolean;
    listOnlyArchived?: boolean;
    groupByArchived?: 'year' | 'month';
    year?: number;
    month?: number;
    ignoreDefaultSort?: boolean;
  } = {}): Observable<Domain.RequestResponse> {
    const headersArr = [
      { key: 'page', value: String(page) },
      { key: 'offset', value: String(offset) },
      { key: 'orderby', value: String(orderBy) },
      { key: 'limit', value: String(limit), shouldAppend: !!limit },
      { key: 'desc', value: String(desc), shouldAppend: desc !== undefined },
      { key: 'query', value: query, shouldAppend: !!query },
      { key: 'folderId', value: folderId, shouldAppend: !!folderId },
      { key: 'haveAFolder', value: String(haveAFolder), shouldAppend: haveAFolder !== undefined },
      { key: 'ignoreFolders', value: String(ignoreFolders) },
      { key: 'isDefault', value: String(isDefault), shouldAppend: isDefault !== undefined },
      { key: 'myCenter', value: String(myCenter), shouldAppend: myCenter !== undefined },
      { key: 'myUser', value: String(myUser), shouldAppend: myUser !== undefined },
      { key: 'environment', value: environment, shouldAppend: !!environment },
      { key: 'stateType', value: stateType, shouldAppend: !!stateType },
      { key: 'listOnlyActive', value: String(listOnlyActive) },
      { key: 'listOnlyArchived', value: String(listOnlyArchived) },
      { key: 'groupByArchived', value: groupByArchived, shouldAppend: !!groupByArchived },
      { key: 'year', value: String(year), shouldAppend: !!year },
      { key: 'month', value: String(month), shouldAppend: !!month },
      { key: 'ignoreDefaultSort', value: String(ignoreDefaultSort), shouldAppend: ignoreDefaultSort !== undefined },
    ];

    let headers = new HttpHeaders();

    headersArr.forEach(({ key, value, shouldAppend = true }) => {
      if (shouldAppend) {
        headers = headers.append(key, value);
      }
    });

    return this.http.get<Domain.RequestResponse>(`${this.situation_api_path}`, { headers });
  }

  listTags(type: 'System' | 'Layer' | 'Situation') {
    let headers = new HttpHeaders();
    headers = headers.append('type', type);
    return this.http
      .get<Domain.RequestResponse>(`/light/api/tag`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Tags[]));
  }

  listAllGroups(): Observable<Domain.Group[]> {
    return this.http.get<Domain.RequestResponse>(`/light/api/group`).pipe(map((res) => res.data as Domain.Group[]));
  }

  getOperationalSituation(id: string): Observable<Domain.Situation | undefined> {
    const headers = new HttpHeaders({ id: id, limit: String(api_default_limit), page: String(api_default_page) });
    return this.http
      .get<Domain.RequestListResponse<Domain.Situation>>(`${this.situation_api_path}`, { headers: headers })
      .pipe(
        map((res) => {
          if (res.data.docs.length === 0) {
            return undefined;
          }
          return res.data.docs[0] as Domain.Situation;
        }),
      );
  }

  getContextOperationalSituation(
    situationId: string | null,
    environment: SituationEnvironmentEnum,
  ): Observable<Domain.Situation | undefined> {
    return this.http.post<Domain.Situation>(
      `${this.situation_api_path}/context`,
      {},
      {
        headers: {
          ...(environment ? { environment } : {}),
          ...(situationId ? { id: situationId } : {}),
        },
      },
    );
  }

  createOperationalSituation(
    situation: {
      Name: string;
      Security?: Domain.SecurityType;
      SensitivityLevel?: Domain.SensitivityLevel;
      Priority?: Domain.SituationPriority;
      IsActive: boolean;
      Description?: string;
      Tags?: string[];
      Folder?: string;
      Layers_ids?: string[];
      ShareWithOutsideYA?: boolean;
      Environment?: SituationEnvironmentEnum;
      stateType?: SituationStateType;
    },
    eventIdsToCopy?: string[],
  ): Observable<Domain.Situation> {
    let headers = new HttpHeaders();

    if (situation.Folder) headers = headers.append('Folder', String(situation.Folder));

    return this.http
      .post<Domain.RequestResponse>(
        `${this.situation_api_path}`,
        { situation: situation, eventIdsToCopy: eventIdsToCopy },
        { headers },
      )
      .pipe(map((res) => res.data as Domain.Situation));
  }

  updateOperationalSituation(situation: Domain.Situation & { Folder?: string }): Observable<Domain.Situation> {
    let headers = new HttpHeaders();

    if (situation.Folder) headers = headers.append('Folder', String(situation.Folder));

    return this.http
      .put<Domain.RequestResponse>(`${this.situation_api_path}`, situation, { headers })
      .pipe(map((res) => res.data as Domain.Situation));
  }

  updateToggleShareCentersOnSituation(situation: Domain.Situation, toggleSharing: Domain.ToggleSharingLayers) {
    return this.http
      .patch<Domain.RequestResponse>(`${this.situation_api_path}/toggleShareCenters`, {
        _id: situation._id,
        ToggleSharingLayers: toggleSharing,
      })
      .pipe(map((res) => res.data as Domain.Situation));
  }

  deleteOperationalSituation(situationId: string): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.append('situationId', situationId);

    return this.http.delete<Domain.RequestResponse>(`${this.situation_api_path}`, { headers });
  }

  updateOperationalSituationLayers(situation: Domain.Situation, layer2remove?: string): Observable<Domain.Situation> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.situation_api_path}/layers`, {
        obj: situation,
        layer_2_remove: layer2remove,
      })
      .pipe(map((res) => res.data as Domain.Situation));
  }

  updateSituationFavorite(situation: Domain.Situation, isFavorite: boolean): Observable<Domain.Situation> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.situation_api_path}/favorite`, {
        _id: situation._id,
        isFavorite: isFavorite,
      })
      .pipe(map((res) => res.data as Domain.Situation));
  }

  listTagsBySituation(situation_id: string) {
    const headers = new HttpHeaders({ situation_id: situation_id });
    return this.http
      .get<Domain.RequestResponse>(`${this.situation_api_path}/listTagsBySituation`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Tags[]));
  }

  situationExport(situation_id: string, includeEventsHistory: boolean = true) {
    const includeEventsHistoryString = includeEventsHistory?.toString();

    let headers = new HttpHeaders().append('Content-Type', 'application/zip').append('situation_id', situation_id);

    if (includeEventsHistory) {
      headers = headers.append('include-events-history-string', includeEventsHistoryString);
    }

    return this.http.get('/light/api/situation/export', {
      responseType: 'blob',
      headers: headers,
    });
  }

  shareSituation(
    situation_id: string,
    sharingGroups: Domain.SharingGroup[],
    sharingGroupRules: Domain.SharingGroupRule[],
    isDefault?: boolean,
  ): Observable<Domain.Situation> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.situation_api_path}/share`, {
        _id: situation_id,
        SharingGroups: sharingGroups,
        SharingGroupRules: sharingGroupRules,
        isDefault: isDefault ? isDefault : undefined,
      })
      .pipe(map((res) => res.data as Domain.Situation));
  }

  shareDraftSituation(
    situation_id: string,
    sharingGroupRules: Domain.SharingGroupRule[],
  ): Observable<Domain.Situation> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.situation_api_path}/shareDraft`, {
        _id: situation_id,
        SharingGroupRules: sharingGroupRules,
      })
      .pipe(map((res) => res.data as Domain.Situation));
  }

  publish(situation_id: string): Observable<Domain.Situation> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.situation_api_path}/publish`, {
        _id: situation_id,
      })
      .pipe(map((res) => res.data as Domain.Situation));
  }
}
