import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageType, TranslationObjectType, TranslationType } from '../../domain';

@Injectable({
  providedIn: 'root',
})
export class TranslationApiService {
  private readonly translation_api_path = '/light/api/translation';

  constructor(private http: HttpClient) {}

  translate(
    objectType: TranslationObjectType,
    objectReference: string,
    language: LanguageType,
  ): Observable<TranslationType> {
    return this.http.post<TranslationType>(`${this.translation_api_path}`, {
      objectType,
      objectReference,
      language,
    });
  }

  translateConfig(
    objectType: TranslationObjectType,
    objectReference: string,
    language: LanguageType,
  ): Observable<TranslationType> {
    return this.http.put<TranslationType>(`${this.translation_api_path}/config`, {
      objectType,
      objectReference,
      language,
    });
  }
}
