import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiKeyEntityType } from "../../domain";

@Injectable()
export class ApiKeyService {

  private readonly BASE_PATH = '/light/api/api-key';

  constructor(
    private readonly http: HttpClient
  ) { }


  geoserver(data: Pick<ApiKeyEntityType, 'parentId' | 'parentType'> ): Observable<ApiKeyEntityType> {
    return this.http.post<ApiKeyEntityType>(`${this.BASE_PATH}/geoserver`, data);
  }

}
