import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IField {
  label: string;
  chip?: string;
}
@Component({
  selector: 'ya-tabs',
  template: `
    <span
      class="tab"
      *ngFor="let field of fields"
      [class.active]="selected === field"
      [class.disabled]="disabledTabs.includes(field)"
      (click)="disabledTabs.includes(field) ? null : select(field)"
    >
      {{ field | translate }}</span
    >
    <span
      class="tab"
      *ngFor="let field of dataFields"
      [class.active]="selected === field.label"
      (click)="select(field.label)"
    >
      {{ field.label | translate }}
      <span class="chip" *ngIf="field.chip">{{ field.chip }}</span>
    </span>
  `,
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() fields: string[] = [];
  @Input() selected: string = '';
  @Input() dataFields: IField[] = [];
  @Input() disabledTabs: string[] = [];

  @Output() change = new EventEmitter<string>();

  ngOnInit(): void {
    if (this.fields.length > 0 && !this.select) {
      this.selected = this.fields[0];
    }
    if (this.dataFields.length > 0 && !this.select) {
      this.selected = this.dataFields[0].label;
    }
  }

  select(field: string) {
    this.selected = field;
    this.change.emit(field);
  }
}
