import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DebugFlagConfig } from '../domain';

export enum DebugFlags {
  SkylightHasHistory = 'SkylightHasHistory',
}

@Injectable({
  providedIn: 'root',
})
export class DebugFlagsService {
  config: DebugFlagConfig = null;

  constructor(private readonly apiService: ApiService) {}

  loadConfig() {
    return this.apiService.userGroup.getDefaultParams().pipe(tap((data) => (this.config = data.DebugFlag)));
  }

  isFlagEnabled(key: DebugFlags) {
    if (this.config && key in this.config) {
      return this.config[key];
    }
    return false;
  }
}
