import { Component, Directive, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ya-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() closeable = false;
  @Output() onClose = new EventEmitter();

  close() {
    this.onClose.next(true);
  }
}

@Directive({
  selector: `ya-menu-top-title`,
  host: { class: 'ya-menu-top-title' },
})
export class MenuTopTitleComponent {}
@Directive({
  selector: `ya-menu-top-actions`,
  host: { class: 'ya-menu-top-actions' },
})
export class MenuTopTitleActionsComponent {}

@Directive({
  selector: `ya-menu-title`,
  host: { class: 'menu-title' },
})
export class MenuTitleComponent {}

@Directive({
  selector: `ya-menu-title-actions`,
  host: { class: 'menu-title-actions' },
})
export class MenuTitleActionsComponent {}

@Directive({
  selector: `ya-menu-body`,
  host: { class: 'menu-body' },
})
export class MenuBodyComponent {}

@Directive({
  selector: `ya-menu-header`,
  host: { class: 'menu-header' },
})
export class MenuHeaderComponent {}

@Directive({
  selector: `ya-menu-footer`,
  host: { class: 'menu-footer' },
})
export class MenuFooterComponent {}
