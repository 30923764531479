<ya-expansion-panel [collapsed]="true" highlight="success">
  <ya-expansion-panel-title>
    logs ({{ dcs.getLogs().length }})
  </ya-expansion-panel-title>
  <ya-expansion-panel-body>
    <pre *ngFor="let log of dcs.getLogs()">
      {{createPrefix(log)}} {{ stringify(log.args) }}
    </pre>
  </ya-expansion-panel-body>
</ya-expansion-panel>

<ya-expansion-panel [collapsed]="true" highlight="warning">
  <ya-expansion-panel-title>
    warnings ({{ dcs.getWarn().length }})
  </ya-expansion-panel-title>
  <ya-expansion-panel-body>
    <pre *ngFor="let warn of dcs.getWarn()">
      {{createPrefix(warn)}} {{ stringify(getWarningWithoutPoints(warn).args) }}
    </pre>
  </ya-expansion-panel-body>
</ya-expansion-panel>

<ya-expansion-panel [collapsed]="true" highlight="danger">
  <ya-expansion-panel-title>
    errors ({{ dcs.getErrors().length }})
  </ya-expansion-panel-title>
  <ya-expansion-panel-body>
    <pre *ngFor="let error of dcs.getErrors()">
      {{createPrefix(error)}} {{stringify(error.args[1]?.stack || error.args) }}
    </pre>
  </ya-expansion-panel-body>
</ya-expansion-panel>