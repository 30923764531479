import { Type } from '@angular/core';
import { WidgetComponent } from '../widget/widget.component';

export interface WidgetState {
  [id: string]: any;
  hooks?: {
    onClose: () => Promise<boolean>;
  };
}

export class Widget {
  component: Type<WidgetComponent>;
  state?: WidgetState;

  constructor(component: Type<WidgetComponent>, state: unknown = {}) {
    this.component = component;
    this.state = state;
  }
}
