import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DynamicWidget } from '../../core/directives/dynamicWidget';
import { DynamicMenu } from '../dynamic-menu/dynamic-menu.model';
import { Widget } from '../model/Widget';
import { WidgetComponent } from '../widget/widget.component';


@Component({
  selector: 'ya-embedded-component',
  template: '<ng-template dynamicWidget></ng-template>'
})
export class EmbeddedComponentComponent implements OnInit, OnDestroy {
  @Input() menu: DynamicMenu;
  @ViewChild(DynamicWidget, { static: true }) dynamicWidget!: DynamicWidget;

  $currentNavigation: Subscription;

  constructor() {
    this.menu = <DynamicMenu>{};
    this.$currentNavigation = <Subscription>{};
  }

  ngOnInit(): void {
    this.$currentNavigation = this.menu.$currentNavigation.subscribe((widget) => {
      this.loadNavigation(widget);
    });
  }

  ngOnDestroy(): void {
    this.$currentNavigation.unsubscribe();
  }

  loadNavigation(widget: Widget) {
    const viewContainerRef = this.dynamicWidget.viewContainerRef;
    viewContainerRef.clear();
    if (!widget.component) return;

    const componentRef = viewContainerRef.createComponent<WidgetComponent>(widget.component);
    componentRef.setInput('parent', this.menu);
    componentRef.setInput('isEmbedded', true);
    componentRef.instance.setInstanceRef(widget);
  }
}
