import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SituationEnvironmentEnum } from '../domain';

@Injectable({
  providedIn: 'root',
})
export class AppCookiesService {

  private readonly CLUSTER_COOKIE_NAME  = 'cluster';
  private readonly sessionTimeoutCookieName = 'timeout';

  constructor(private cookieService: CookieService) {}

  setSituationEnviroment(env: SituationEnvironmentEnum) {
    this.cookieService.set(this.CLUSTER_COOKIE_NAME, env);
  }

  getSituationEnviroment(): SituationEnvironmentEnum {
    return this.cookieService.get(this.CLUSTER_COOKIE_NAME) as SituationEnvironmentEnum;
  }

  deleteSituationEnviroment() {
    return this.cookieService.delete(this.CLUSTER_COOKIE_NAME);
  }

  getSessionTimeoutCookie(): any {
    return this.cookieService.get(this.sessionTimeoutCookieName);
  }

}
