import { APP_INITIALIZER, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsService } from '../utils/permissions.service';

export function userInitializerFactory(
  translate: TranslateService,
  injector: Injector,
  permissionsService: PermissionsService,
) {
  translate.addLangs(['en', 'fr', 'pt', 'es']);
  translate.setDefaultLang('en');
  return async () => {
    const user = await permissionsService.load();
    return translate.use(user.Preferences?.Language || 'en').toPromise();
  };
}

export const _TranslateProvider_ = {
  provide: APP_INITIALIZER,
  useFactory: userInitializerFactory,
  deps: [TranslateService, Injector, PermissionsService],
  multi: true,
};
