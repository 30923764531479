import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateUtc',
})
export class DateUtcPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: Date | string | null | undefined, seconds?: any): string | null {
    if (!seconds) return this.datePipe.transform(value, 'yyyy-MM-dd HH:mm (UTC)', 'UTC');
    else return this.datePipe.transform(value, 'yyyy-MM-dd HH:mm:ss (UTC)', 'UTC');
  }
}
