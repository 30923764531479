import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ViewSite, ViewSiteDetail } from '../../domain/view-site.domain';

@Injectable({
  providedIn: 'root',
})
export class ViewSiteApiService {
  private readonly viewSites_api_path = '/light/api/viewsites';

  constructor(private http: HttpClient) {}

  getViewSites(): Observable<ViewSite[]> {
    return this.http.get<any>(`${this.viewSites_api_path}`);
  }

  createViewSite(viewSite: ViewSite): Observable<ViewSite> {
    return this.http.post<ViewSite>(this.viewSites_api_path, viewSite);
  }

  updateView(viewSite: ViewSiteDetail): Observable<ViewSiteDetail> {
    return this.http.put<ViewSiteDetail>(`${this.viewSites_api_path}`, viewSite);
  }

  deleteView(viewSite: ViewSiteDetail): Observable<ViewSiteDetail> {
    const options = {
      headers: new HttpHeaders(),
      body: viewSite,
    };
    return this.http.delete<ViewSiteDetail>(`${this.viewSites_api_path}`, options);
  }

  updateViewOrder(viewSite: any): Observable<any> {
    return this.http.patch<any>(`${this.viewSites_api_path}/order`, viewSite);
  }
}
