<ya-menu-top-title>
  <ng-content select="ya-menu-title"></ng-content>
  <ya-menu-top-actions>
    <ng-content select="ya-menu-title-actions"></ng-content>
    <ya-icon matTooltip="{{ 'BUTTON.CLOSE' | translate }}" *ngIf="closeable" (click)="close()"
      >ya-editing-remove</ya-icon
    >
  </ya-menu-top-actions>
</ya-menu-top-title>

<ng-content select="ya-menu-header"> </ng-content>

<ng-content select="ya-menu-body"> </ng-content>

<ng-content select="ya-menu-footer"> </ng-content>
