enum TabContextsNames {
  Dashboard = 'dashboard',
  ShipDatabase = 'shipdatabase:',
}

export class TabContextsNamesUtil {
  static isContext(valueToTest: string, contextName: TabContextsNames) {
    return valueToTest.startsWith(contextName);
  }
}

export default TabContextsNames;
