import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import * as Domain from '../../domain';

@Injectable({
  providedIn: 'root',
})
export class IconApiService {
  constructor(private http: HttpClient) {}

  private readonly icon_api_path = '/light/api/icon';

  listMsaIcons(filtered?: string) {
    let headers = new HttpHeaders();
    if (filtered) headers = headers.append('filtered', filtered);
    return this.http
      .get<Domain.RequestResponse>(this.icon_api_path, { headers: headers })
      .pipe(map((res) => res.data as Domain.Icons[]));
  }

  updateIconVisibility(icon: any): Observable<Domain.User> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.icon_api_path}/visibility`, icon)
      .pipe(map((res) => res.data));
  }

  createIcons(icon: {
    Name: string;
    IconURL: string;
    Description?: string;
    GroupName: string;
  }): Observable<Domain.Icons> {
    return this.http
      .post<Domain.RequestResponse>(`${this.icon_api_path}`, icon)
      .pipe(map((res) => res.data as Domain.Icons));
  }

  updateIcons(icon: {
    _id: string;
    Name: string;
    IconURL: string;
    Description?: string;
    GroupName: string;
    Visible: boolean;
  }): Observable<Domain.Icons> {
    return this.http
      .put<Domain.RequestResponse>(`${this.icon_api_path}`, icon)
      .pipe(map((res) => res.data as Domain.Icons));
  }

  deleteIcons(icon: Domain.Icons): Observable<any> {
    return this.http.delete<Domain.RequestResponse>(`${this.icon_api_path}/${icon._id}`);
  }
}
