import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InlineSVGModule } from 'ng-inline-svg';
import { firstValueFrom } from 'rxjs';
import { _TranslateModule_ } from 'src/app/core/modules/translate.module';
import { DebugFlagsService } from 'src/app/core/utils/debug-flag.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastManagerService } from './components/toast-manager/toast-manager.service';
import { ApiService } from './core/api/api.service';
import { CoreModule } from './core/core.module';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { _TranslateProvider_ } from './core/providers/translate.provider';
import { DebugCollectorService } from './core/utils/debug-collector.service';
import { ErrorManagerService } from './core/utils/error-manager.service';
import { FeatureFlagsService } from './core/utils/feature-flag.service';
import { ClusterHeaderHttpInterceptorService } from './core/interceptors/cluster-header.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    InlineSVGModule.forRoot(),
    _TranslateModule_,
  ],
  providers: [
    _TranslateProvider_,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClusterHeaderHttpInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (featureFlagsService: FeatureFlagsService) => () => firstValueFrom(featureFlagsService.loadConfig()),
      deps: [FeatureFlagsService, ApiService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (debugFlagsService: DebugFlagsService) => () => firstValueFrom(debugFlagsService.loadConfig()),
      deps: [DebugFlagsService, ApiService],
      multi: true,
    },
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'ya-dynamic-menu-overlay' },
    },
    ErrorManagerService, // need global initialization
    ToastManagerService, // need global initialization
    DebugCollectorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
