import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ReplayWorkerMessengerService } from 'src/app/core/utils/worker/replay/replay-worker-messenger.service';
import { WindowManagerService } from './components/window-manager/window-manager.service';
import { Window } from './components/window-manager/window/window.model';
import { DatabaseService } from './core/database/database.service';
import { SocketService } from './core/socket/socket.service';
import { DefaultParamsService } from './core/utils/default-params.service';
import { DebugConsoleComponent } from './widgets/debug-console/debug-console.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'HMI';

  constructor(
    private socketService: SocketService,
    private databaseService: DatabaseService,
    private readonly wms: WindowManagerService,
    private defaultParamsService: DefaultParamsService,
    private replayWorkerMessengerService: ReplayWorkerMessengerService,
  ) {
    this.databaseService.dbPush$.subscribe((data) => {
      if (this.workerReady) {
        this.databaseWorker?.postMessage(data);
      } else {
        this.workerReadyBuffer.push(data);
      }
    });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.socketService.emitRemoveTab();
  }

  @HostListener('keydown', ['$event']) async onKeyDown(e) {
    const params = await lastValueFrom(this.defaultParamsService.get());
    if (params.Debug.isActive && e.shiftKey && e.altKey && e.code === 'KeyD') {
      this.wms.open(new Window('Debug Collector', DebugConsoleComponent, true));
    }
  }
  socketWorker: Worker | undefined;
  databaseWorker: Worker | undefined;
  workerReady = false;
  workerReadyBuffer: any[] = [];
  replayWorker: Worker | undefined;

  // sync usage before close tab? we can't guarantee the data will be saved before the tab close
  // @HostListener('window:beforeunload', ['$event'])
  // async beforeunloadHandler() {
  //   await this.usageService.sync()
  // }

  ngOnInit(): void {
    if (typeof Worker !== 'undefined') {
      this.socketWorker = new Worker(new URL('./socket.worker', import.meta.url));

      this.socketWorker.onmessage = ({ data }) => {
        this.socketService.$sktOnReceive.next({ event: data.event, data: data.msg });
      };
      this.socketService.sktEmitToServer$.subscribe((ob) => {
        this.socketWorker?.postMessage({ type: 'emit', message: ob });
      });

      this.databaseWorker = new Worker(new URL('./database.worker', import.meta.url));

      this.databaseWorker.onmessage = ({ data }) => {
        if (typeof data == 'string' && data == 'workerReady') {
          this.workerReady = true;
          for (const d of this.workerReadyBuffer) {
            this.databaseWorker?.postMessage(d);
          }
          this.workerReadyBuffer = [];
        } else {
          this.databaseService.$dbPull.next(data);
        }
      };

      this.replayWorker = new Worker(new URL('./replay.worker', import.meta.url));
      this.replayWorkerMessengerService.initWorker(this.replayWorker);
    } else {
      console.error('Webworker not supported');
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  }
}
