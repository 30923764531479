import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { SocketService } from 'src/app/core/socket/socket.service';
import { ErrorManagerService } from 'src/app/core/utils/error-manager.service';
import { Highlight } from './toast/toast.component';
import { Toast } from './toast/toast.model';

@Injectable({
  providedIn: 'root',
})
export class ToastManagerService {
  toasts: Toast[] = [];
  $toasts: BehaviorSubject<Toast[]>;

  constructor(
    private errorManager: ErrorManagerService,
    private socketService: SocketService,
    private readonly translateService: TranslateService,
  ) {
    this.$toasts = new BehaviorSubject([] as Toast[]);

    this.errorManager.$errorGeneralNotification.subscribe((error) => {
      this.invoke(
        new Toast({
          message: error.message,
          highlight: Highlight.DANGER,
          hasAction: true,
          timeout: 10000,
        }),
      );
    });

    this.socketService.connectionNotification$.subscribe((state) => {
      let highlight = Highlight.DANGER; // off
      let message = this.translateService.instant('LIGHTMSA.CONNECTION.CONNECTION_DISCONNECTED'); // off
      if (state == 'on') {
        highlight = Highlight.SUCCESS;
        message = this.translateService.instant('LIGHTMSA.CONNECTION.CONNECTION_CONNECTED');
      }

      this.invoke(
        new Toast({
          message: message,
          highlight: highlight,
          hasAction: false,
          timeout: 5000,
          type: 'system',
        }),
      );
    });
  }

  getByKey(key: string): Toast | null {
    return this.toasts.find(t => t.key === key);
  }

  invoke(toast: Toast): void {
    if (toast.key) {
      const t = this.getByKey(toast.key);
      if (t) {
        Object.assign(t, toast);
        return;
      }
    }
    if (toast.hasAction || toast.type === 'system') {
      this.toasts.unshift(toast);
    } else {
      this.toasts.push(toast);
    }
    this.$toasts.next(this.toasts);
  }

  dismiss(toast: Toast) {
    const idx = this.toasts.indexOf(toast);
    if (idx == -1) {
      return;
    }
    this.toasts.splice(idx, 1);
    this.$toasts.next(this.toasts);
  }
}
