import { Component, Input } from '@angular/core';

export enum Appearance {
  NORMAL = 'normal',
  SMALL = 'small',
  BIG = 'big',
}

@Component({
  selector: 'ya-ripple',
  templateUrl: './ripple.component.html',
  styleUrls: ['./ripple.component.scss'],
  host: {
    '[class]': 'appearance',
  },
})
export class RippleComponent {
  @Input() appearance: string | Appearance = Appearance.NORMAL;
  @Input() color: string = undefined;
}
