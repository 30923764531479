import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { TransformDistancePipe } from '../pipes/transform-distance.pipe';

@NgModule({
  declarations: [],
  imports: [HttpClientModule, TranslateModule],
  providers: [CookieService, TransformDistancePipe],
})
export class CoreModule {}
