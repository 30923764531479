import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import * as Domain from '../../domain';
import { VesselEntityResponse, VesselInspectionResponse, VesselNoteResponse } from '../../domain';

@Injectable({
  providedIn: 'root',
})
export class ShipDatabaseApiService {
  constructor(private http: HttpClient) {}

  private readonly vessel_api_path = '/light/api/vessel';
  private readonly vessel_entity_api_path = '/light/api/vessel-entity';
  private readonly vessel_note_api_path = '/light/api/vessel-note';
  private readonly vessel_inspecion_api_path = '/light/api/vessel-inspection';
  private readonly vessel_timeline_api_path = '/light/api/vessel-timeline';

  list({
    search,
    interest,
    offset,
    limit,
    cluster,
  }: {
    search?: string;
    vesselToTop?: string;
    interest?: boolean;
    offset?: number;
    limit?: number;
    cluster?: string
  } = {}): Observable<Domain.RequestListResponse<Domain.VesselResponse>> {
    let params = new HttpParams().append('offset', String(offset)).append('limit', String(limit));

    if (search) {
      params = params.append('search', search);
    }
    if (interest) {
      params = params.append('interest', interest);
    }

    if (cluster) {
      params = params.append('cluster', cluster);
    }

    return this.http.get<Domain.RequestListResponse<Domain.VesselResponse>>(`${this.vessel_api_path}/list`, { params });
  }

  listDefaultCustomVesselPhoto() : Observable<Domain.MsaCustomVesselsPhotos[]> {
    return this.http
      .get<Domain.MsaCustomVesselsPhotos[]>(`/light/api/vessel/custom-vessel-photos`)
  }

  getVessel(id: string): Observable<Domain.VesselResponse> {
    const params = new HttpParams().append('Vessel', String(id));

    return this.http.get<Domain.VesselResponse>(`${this.vessel_api_path}`, { params });
  }

  getVesselTimeline({
    offset,
    limit,
    search,
    filter,
    cluster,
  }: {
    offset?: number;
    limit?: number;
    search?: string;
    summedUp?: boolean;
    filter?: string;
    cluster?: string;
  } = {}): Observable<any> {
    let params = new HttpParams().append('search', String(search));
    if (offset) params = params.append('offset', String(offset));
    if (limit) params = params.append('limit', String(limit));
    if (filter) params = params.append('filter', String(filter));
    if (cluster) params = params.append('cluster', String(cluster));

    const headers = new HttpHeaders({
      getLocalAndShared: String(true),
    });

    return this.http.get<any[]>(`${this.vessel_timeline_api_path}`, { headers, params })
  }

  changePhotoMetadata({
    vesselId, 
    contentId, 
    wasTaken,
    derivation,
    author
  } : {
    vesselId: string, 
    contentId: string, 
    wasTaken,
    derivation: string,
    author: string
  }) {
    return this.http.patch<Domain.RequestResponse>(`${this.vessel_api_path}/change-photo-metadata`, {
      VesselId: String(vesselId),
      ContentId: String(contentId),
      DateWasTaken: String(wasTaken),
      Derivation: derivation,
      Author: author
     });
  }

  changeDateWhenVesselPhotoWasTaken(vesselId: string, contentId: string, wasTaken: Date) {
    return this.http.patch<Domain.RequestResponse>(`${this.vessel_api_path}/change-date-photo-was-taken`, {
      VesselId: String(vesselId),
      ContentId: String(contentId),
      DateWasTaken: wasTaken
     });
  }

  updateVessel(vessel: Domain.VesselResponse) {
    return this.http.patch<Domain.RequestResponse>(`${this.vessel_api_path}`, vessel).pipe(
      map((res) => {
        return res.data as Domain.VesselResponse;
      }),
    );
  }

  createEntity(entity: VesselEntityResponse) {
    return this.http.post<Domain.RequestResponse>(`${this.vessel_entity_api_path}`, entity).pipe(
      map((res) => {
        return res.data as Domain.VesselEntityResponse;
      }),
    );
  }

  updateEntity(entity: VesselEntityResponse) {
    return this.http.put<Domain.RequestResponse>(`${this.vessel_entity_api_path}`, entity).pipe(
      map((res) => {
        return res.data as Domain.VesselEntityResponse;
      }),
    );
  }

  deactivateEntity(id: string) {
    const params = new HttpParams().append('_id', String(id));
    return this.http.delete<Domain.RequestResponse>(`${this.vessel_entity_api_path}/deactivate`, { params }).pipe(
      map((res) => {
        return res.data as Domain.VesselEntityResponse;
      }),
    );
  }

  deleteEntity(id: string) {
    const params = new HttpParams().append('_id', String(id));
    return this.http.delete<Domain.RequestResponse>(`${this.vessel_entity_api_path}`, { params }).pipe(
      map((res) => {
        return res.data as Domain.VesselEntityResponse;
      }),
    );
  }

  createNote(note: VesselNoteResponse) {
    return this.http.post<Domain.RequestResponse>(`${this.vessel_note_api_path}`, note).pipe(
      map((res) => {
        return res.data as Domain.VesselNoteResponse;
      }),
    );
  }

  updateNote(note: VesselNoteResponse) {
    return this.http.put<Domain.RequestResponse>(`${this.vessel_note_api_path}`, note).pipe(
      map((res) => {
        return res.data as Domain.VesselNoteResponse;
      }),
    );
  }

  deleteNote(id: string) {
    const params = new HttpParams().append('_id', String(id));
    return this.http.delete<Domain.RequestResponse>(`${this.vessel_note_api_path}`, { params }).pipe(
      map((res) => {
        return res.data as Domain.VesselNoteResponse;
      }),
    );
  }

  deleteInspection(id: string) {
    const params = new HttpParams().append('_id', String(id));
    return this.http.delete<Domain.RequestResponse>(`${this.vessel_inspecion_api_path}`, { params }).pipe(
      map((res) => {
        return res.data as Domain.VesselInspectionResponse;
      }),
    );
  }

  createInspection(inspection: VesselInspectionResponse) {
    return this.http.post<Domain.RequestResponse>(`${this.vessel_inspecion_api_path}`, inspection).pipe(
      map((res) => {
        return res.data as Domain.VesselInspectionResponse;
      }),
    );
  }

  updateInspection(inspection: VesselInspectionResponse) {
    return this.http.put<Domain.RequestResponse>(`${this.vessel_inspecion_api_path}`, inspection).pipe(
      map((res) => {
        return res.data as Domain.VesselInspectionResponse;
      }),
    );
  }

  setVesselOfInterest(vessel_id: string, set: boolean) {
    return this.http.patch<Domain.RequestResponse>(`${this.vessel_api_path}/interest`, { vessel_id, set });
  }

  setDefaultContent(vessel_id: string, content_id: string) {
    return this.http.patch<Domain.RequestResponse>(`${this.vessel_api_path}/default-content`, {
      vessel_id,
      content_id,
    });
  }

  removeContent(vessel_id: string, content_id: string, inspection_id?: string) {
    let params = new HttpParams();
    if (inspection_id) params = params.set('inspection_id', String(inspection_id)).set('content_id', String(content_id));
    params = params.set('vessel_id', String(vessel_id)).set('content_id', String(content_id));
    return this.http.delete<Domain.RequestResponse>(`${this.vessel_api_path}/remove-content`, { params: params });
  }

  getReport(id: string) {
    const params = new HttpParams().append('_id', String(id));
    const headers = new HttpHeaders().append('Content-Type', 'application/zip');
    return this.http.get(`${this.vessel_api_path}/export`, {
      responseType: 'blob',
      headers,
      params,
    });
  }
}
