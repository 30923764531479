<ya-menu>
  <ya-menu-title>
    Debug console
  </ya-menu-title>
  <ya-menu-header>
    <ya-tabs [fields]="fields" [selected]="selected" (change)="handleSelect($event)"></ya-tabs>
  </ya-menu-header>
  <ya-menu-body>
    <ya-embedded-component [menu]="currentMenu" *ngIf="currentMenu" />
  </ya-menu-body>
  <ya-menu-footer>
    <ya-button (click)="handleDownload()">
      <ya-icon >ya-arrows-download</ya-icon>
      Download
    </ya-button>
  </ya-menu-footer>
</ya-menu>
