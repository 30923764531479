import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as Domain from '../../domain';
import { api_default_limit } from '../api.constants';

@Injectable({
  providedIn: 'root',
})
export class CpaApiService {
  constructor(private http: HttpClient) {}

  private readonly api_path = '/light/api/cpaObject/';

  createCpaObject(cpaObject: {
    TrackObjects: string[];
    ActivedFor: {
      Situation: string;
    }[];
    RefreshTimeInSec: number;
  }): Observable<Domain.CpaObject> {
    return this.http.post(this.api_path, cpaObject).pipe(map((res) => res as Domain.CpaObject));
  }

  listCpaObjects({
    ActivedForUser,
    ActivedForSituation,
    limit = api_default_limit,
    offset = 0,
  }: {
    ActivedForUser?: string;
    ActivedForSituation?: string;
  } & Domain.IPaginatedRequest = {}) {
    let params = new HttpParams().set('limit', String(limit)).set('offset', String(offset));

    if (ActivedForUser) params = params.set('ActivedForUser', ActivedForUser);
    if (ActivedForSituation) params = params.set('ActivedForSituation', ActivedForSituation);

    return this.http.get<Domain.RequestResponse>(this.api_path, { params: params });
  }

  updateCpaObject(cpaObject: { _id: string; RefreshTimeInSec: number }): Observable<Domain.CpaObject> {
    return this.http.put(this.api_path, cpaObject).pipe(map((res) => res as Domain.CpaObject));
  }

  updateActiveForCpaObject(params: {
    _id: string;
    IsActive: boolean;
    SituationId: string;
  }): Observable<Domain.CpaObject> {
    return this.http.put(`${this.api_path}activedFor`, params).pipe(map((res) => res as Domain.CpaObject));
  }

  deleteCpaObject(cpaObjectId: string): Observable<Domain.CpaObject> {
    const params = new HttpParams().append('_id', cpaObjectId);

    return this.http.delete(this.api_path, { params: params }).pipe(map((res) => res as Domain.CpaObject));
  }
}
