import { Component, Directive, Input } from '@angular/core';

export enum Highlight {
  SUCCESS = 'success',
  WARN = 'warning',
  DANGER = 'danger',
  NORMAL = 'normal',
}
@Component({
  selector: 'ya-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  host: {
    '[class]': 'highlight',
  },
})
export class ExpansionPanelComponent {
  @Input() collapsed: boolean = false;
  @Input() highlight: string | Highlight = Highlight.NORMAL;
  @Input() collapsible: boolean = true;
  @Input() collapsedIcon: string = 'ya-arrows-chevron-up';
  @Input() expandedIcon: string = 'ya-arrows-chevron-down';

  toggleCollapse() {
    if (!this.collapsible) {
      return;
    }
    this.collapsed = !this.collapsed;
  }
}

@Directive({
  selector: `ya-expansion-panel-title`,
  host: { class: 'expansion-panel-title' },
})
export class ExpansionPanelTitle {}

@Directive({
  selector: `ya-expansion-panel-subtitle`,
  host: { class: 'expansion-panel-subtitle' },
})
export class ExpansionPanelSubTitle {}

@Directive({
  selector: `ya-expansion-panel-body`,
  host: { class: 'expansion-panel-body' },
})
export class ExpansionPanelBody {}
