import { Component, OnInit } from '@angular/core';
import { WidgetComponent } from 'src/app/components/widget/widget.component';
import { WindowManagerService } from 'src/app/components/window-manager/window-manager.service';
import { Window } from 'src/app/components/window-manager/window/window.model';
import { DebugConsoleNavigationComponent } from './debug-console-navigation/debug-console-navigation.component';
import { DefaultParamsService } from 'src/app/core/utils/default-params.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'ya-debug-console',
  templateUrl: './debug-console.component.html',
  styleUrls: ['./debug-console.component.scss']
})
export class DebugConsoleComponent extends WidgetComponent implements OnInit {
  code: string;
  isActive: boolean = false;
  isProtected: boolean = false;
  validationCode: string;

  constructor(
    private readonly wms: WindowManagerService,
    private defaultParamsService: DefaultParamsService
  ) {
    super();
  }

  async ngOnInit() {
    const params = await lastValueFrom(this.defaultParamsService.get());
    this.code = params.Debug.password;
    this.isActive = params.Debug.isActive;
    this.isProtected = params.Debug.isProtected;

    if(!this.isProtected) {
      this.start();
    }
  }

  isValid(): boolean {
    return !this.isProtected || this.code === this.validationCode;
  }

  start(): void {
    if(!this.isValid()) {
      return ;
    }
    this.close();
    this.wms.open(new Window('debug-console', DebugConsoleNavigationComponent, true, true, true, true));
  }

}
