import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as Domain from '../../domain';
import { MSAObjectMap, SituationEnvironmentEnum, SituationStateType } from '../../domain';
import { api_default_limit, api_default_page } from '../api.constants';

@Injectable({
  providedIn: 'root',
})
export class MsaObjectApiService {
  constructor(private http: HttpClient) { }

  private readonly msaobject_api_path = '/light/api/MSAObject';

  getMSAObject(id: string, layerId: string, situationId?: string): Observable<Domain.MSAObject> {
    let headers = new HttpHeaders({
      layer_id: layerId,
      id: id,
    });

    if (situationId) headers = headers.append('situationId', situationId);

    return this.http
      .get<Domain.RequestResponse>(`${this.msaobject_api_path}/details`, { headers: headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  createMSAObject(
    msaObject: Domain.CreateMSAObjectDTO,
    original?: string,
    situationId?: string,
    cluster?: string,
  ): Observable<Domain.MSAObject> {
    let headers = new HttpHeaders();

    if (cluster) {
      headers = headers.append('cluster', cluster);
    }

    if (situationId) {
      headers = headers.append('situationId', situationId);
    }

    if (original) {
      headers = headers.append('original', original);
      return this.http
        .post<Domain.RequestResponse>(this.msaobject_api_path, msaObject, { headers })
        .pipe(map((res) => res.data as Domain.MSAObject));
    }

    return this.http
      .post<Domain.RequestResponse>(this.msaobject_api_path, msaObject, { headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  duplicateMsaObjectList(
    originalIds: string[],
    layerId,
    limit: number = api_default_limit,
    page: number = api_default_page,
  ) {
    const headers = new HttpHeaders({
      limit: String(limit),
      page: String(page),
    });
    return this.http
      .post<Domain.RequestResponse>(
        `${this.msaobject_api_path}/duplicateMsaObjectList`,
        { originalIds, layerId },
        { headers },
      )
      .pipe(map((res) => res.data as Domain.MSAObject[]));
  }

  updateMSAObject(msaObject: Domain.MSAObject, situationId?: string): Observable<Domain.MSAObject> {
    let headers = new HttpHeaders();

    const layerId = msaObject.Layer_id || (msaObject as unknown as MSAObjectMap)?.layerIds?.[0] || '';
    headers = headers.append('layer_id', layerId);

    if (situationId) headers = headers.append('situationId', situationId);

    return this.http
      .put<Domain.RequestResponse>(this.msaobject_api_path, msaObject, { headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  deleteMSAObject(id: string): Observable<Domain.MSAObject> {
    const headers = new HttpHeaders({ id: id });
    return this.http
      .delete<Domain.RequestResponse>(this.msaobject_api_path, { headers: headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  removeMSAObjectFromLayer(id: string, layerId: string): Observable<Domain.MSAObject> {
    const headers = new HttpHeaders({ id: id, layer_id: layerId });
    return this.http
      .patch<Domain.RequestResponse>(`${this.msaobject_api_path}/removeFromLayer`, undefined, { headers: headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  addMSAObjectToLayer(id: string, layerId: string): Observable<Domain.MSAObject> {
    const headers = new HttpHeaders({ id: id, layer_id: layerId });
    return this.http
      .patch<Domain.RequestResponse>(`${this.msaobject_api_path}/addToLayer`, undefined, { headers: headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  addMSAObjectListToLayer(ids: string[], layerId: string): Observable<Domain.MSAObject[]> {
    const headers = new HttpHeaders({ layer_id: layerId });
    return this.http
      .patch<Domain.RequestResponse>(`${this.msaobject_api_path}/addListToLayer`, { ids }, { headers: headers })
      .pipe(map((res) => res.data as Domain.MSAObject[]));
  }

  undoMSAObjectLastHistory(msaObjectId: string): Observable<Domain.MSAObject> {
    const headers = new HttpHeaders({ MSAObject_id: msaObjectId });
    return this.http
      .patch<Domain.RequestResponse>(`${this.msaobject_api_path}/undoLastHistory`, undefined, { headers: headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  listMSAObjectHistory(
    msaobject_id: string,
    startDate: string,
    endDate: string,
    limit: number = api_default_limit,
    page: number = api_default_page,
  ): Observable<Domain.RequestResponse> {
    const headers = new HttpHeaders({
      msaobject_id: msaobject_id,
      startDate: startDate,
      endDate: endDate,
      limit: String(limit),
      page: String(page),
    });
    return this.http.get<Domain.RequestResponse>(`${this.msaobject_api_path}/history`, { headers: headers });
  }

  listSkylightHistory(
    skylight_id: string,
    interval: number,
    limit: number = api_default_limit,
    page: number = api_default_page,
  ): Observable<Domain.RequestResponse> {
    const headers = new HttpHeaders({
      skylightobject_id: skylight_id,
      interval: interval.toString(),
      limit: String(limit),
      page: String(page),
    });
    return this.http.get<Domain.RequestResponse>(`${this.msaobject_api_path}/skylight_history`, { headers: headers });
  }

  ExportCSV(msaObject_id: string) {
    return this.http.get(`${this.msaobject_api_path}/exportCSV`, {
      responseType: 'blob',
      //headers: new HttpHeaders().append('Content-Type', 'text/csv').append('layer_id', layer_id)
      headers: new HttpHeaders().append('Content-Type', 'application/zip').append('obj_id', msaObject_id),
    });
  }

  ExportKMZ(msaObject_id: string) {
    return this.http.get(`${this.msaobject_api_path}/exportKMZ`, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/kmz').append('obj_id', msaObject_id),
    });
  }

  listLayerMsaObjects(
    id: string,
    situationId?: string,
    parent_layer_id?: string,
    limit: number = api_default_limit,
    page: number = api_default_page,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({
      layer_id: id,
      limit: String(limit),
      page: String(page),
    });

    if (situationId) headers = headers.append('situationId', situationId);

    if (parent_layer_id) headers = headers.append('parent_layer_id', parent_layer_id);

    if (parent_layer_id) headers = headers.append('parent_layer_id', parent_layer_id);

    return this.http.get<Domain.RequestResponse>(`/light/api/MSAObject`, { headers: headers });
  }

  findMsaObjectByLayerId(id: string, msaobject_id: string): Observable<Domain.RequestResponse> {
    const headers = new HttpHeaders({
      layer_id: id,
      msaobject_id: msaobject_id,
    });

    return this.http.get<Domain.RequestResponse>(`/light/api/MSAObject/findMsaObjectByLayerId`, { headers: headers });
  }

  listMsaObjectsDefaultProperties(property: string) {
    const headers = new HttpHeaders({ property: property });
    return this.http
      .get<Domain.RequestResponse>(`/light/api/layer/defaultProperties`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  getDefaultParams(): Observable<Domain.IDefaultParams> {
    return this.http.get(`/light/api/user/defaultparams`).pipe(map((res: any) => res.data[0]));
  }

  listMsaIcons(filtered?: string) {
    let headers = new HttpHeaders();
    if (filtered) headers = headers.append('filtered', filtered);
    return this.http
      .get<Domain.RequestResponse>(`/light/api/icon`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Icons[]));
  }
}
