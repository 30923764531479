import { Component, Input } from '@angular/core';
import { WidgetComponent } from 'src/app/components/widget/widget.component';
import { DebugCollectorService, ECategory, Log } from 'src/app/core/utils/debug-collector.service';
import { DateUtcPipe } from 'src/app/pipes/date-utc.pipe';

@Component({
  selector: 'ya-debug-console-logs',
  templateUrl: './debug-console-logs.component.html',
  styleUrls: ['../debug-console.scss']
})
export class DebugConsoleLogsComponent extends WidgetComponent {
  @Input() isEmbedded = false;

  constructor(
    protected readonly dcs: DebugCollectorService,
    private dateUtc: DateUtcPipe
  ) {
    super();
  }

  protected createPrefix(entry: Log): string {
    const type = entry.type;
    return `[${type}] ${this.dateUtc.transform(entry.timestamp)}`
  }

  protected stringify(data: any): string {
    return JSON.stringify(data, null, 2);
  }

  protected getWarningWithoutPoints(log: Log): Log {
    if (log.category === ECategory.MAP) {
      const newLog = { ...log };
      delete newLog.args.points;
      return newLog;
    } return log;
  }

}
