import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DirexAlertType } from '../../domain';
import { api_default_limit } from '../api.constants';
import * as Domain from '../../domain';

@Injectable({
  providedIn: 'root',
})
export class DirexLoudSpeakerService {
  constructor(private http: HttpClient) {}

  private readonly direx_loud_speaker_api_path = '/light/api/loud-speaker';

  list({ limit = api_default_limit, offset = 0 }: Domain.IPaginatedRequest = {}) {
    const params = new HttpParams().set('limit', String(limit)).set('offset', String(offset));
    return this.http.get<Domain.RequestResponse>(`${this.direx_loud_speaker_api_path}`, { params: params });
  }

  addLoudSpeaker(data: { Title: string; Description: string; AlertType: DirexAlertType }) {
    return this.http.post(`${this.direx_loud_speaker_api_path}`, data);
  }

  userReadLoudSpeaker(loudSpeakerId: string) {
    return this.http.patch(`${this.direx_loud_speaker_api_path}/confirm-user-read`, { loudSpeakerId });
  }
}
