import { Type } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Widget } from '../../model/Widget';
import { WidgetComponent } from '../../widget/widget.component';

export enum ResizeDirection {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT'
}

export interface IWindowPositionEvent {
  xPos: number;
  yPos: number;
  width: number;
  height: number;
}

export interface IWindowOutput {
  $output: Subject<unknown>;
  $error: Subject<unknown>;
  $close: Subject<unknown>;
  $minimize: Subject<unknown>;
  $maximize: Subject<unknown>;
}

export class Window {
  title: string;
  closeable: boolean;
  draggable: boolean;
  minimizable: boolean;
  isMinimized: boolean;
  resizable: boolean;
  isHighlight: boolean;

  $output: Subject<unknown>;
  $error: Subject<unknown>;
  $close: Subject<unknown>;
  $minimize: Subject<unknown>;
  $maximize: Subject<unknown>;
  $position: Subject<IWindowPositionEvent>;

  _xPos: number;
  _yPos: number;
  zIndex: number;
  offsetX?: number;
  offsetY?: number;
  _width: number;
  _height: number;

  isReady: boolean;

  private navigations: Widget[] = [];
  $currentNavigation: BehaviorSubject<Widget> = new BehaviorSubject(<Widget>{});

  constructor(
    title: string,
    component: Type<WidgetComponent>,
    closeable: boolean = false,
    draggable: boolean = false,
    minimizable: boolean = false,
    resizable = false,
    private data?: unknown,
  ) {
    this.title = title;
    this.closeable = closeable;
    this.draggable = draggable;
    this.minimizable = minimizable;

    this.isMinimized = false;
    this.resizable = resizable;
    this.xPos = 0;
    this.yPos = 0;
    this.zIndex = 0;
    this.offsetX = 0;
    this.offsetY = 0;
    this.width = 0;
    this.height = 0;

    this.isReady = false;

    if (component) this.setNavigation(component, data);

    this.$output = new Subject<unknown>();
    this.$error = new Subject<unknown>();
    this.$close = new Subject<unknown>();
    this.$minimize = new Subject<unknown>();
    this.$maximize = new Subject<unknown>();
    this.$position = new Subject<IWindowPositionEvent>();
  }

  public get xPos() {
    return this._xPos;
  }

  public set xPos(xPos: number) {
    this._xPos = xPos;
    this.emitPosition();
  }

  public get yPos() {
    return this._yPos;
  }

  public set yPos(yPos: number) {
    this._yPos = yPos;
    this.emitPosition();
  }

  public get width() {
    return this._width;
  }

  public set width(width: number) {
    this._width = width;
    this.emitPosition();
  }

  public get height() {
    return this._height;
  }

  public set height(height: number) {
    this._height = height;
    this.emitPosition();
  }

  private emitPosition() {
    if (!this.$position) return;
    this.$position.next({
      xPos: this.xPos,
      yPos: this.yPos,
      width: this.width,
      height: this.height
    })
  }

  public setNavigation(component: Type<WidgetComponent>, data?: unknown): void {
    const widget = new Widget(component, data);
    this.navigations.push(widget);
    this.$currentNavigation.next(widget);
  }

  public backNavigation(): unknown {
    if (!this.hasBack()) {
      return;
    }

    this.navigations.pop();
    const navigation = this.navigations[this.navigations.length - 1];

    this.$currentNavigation.next(navigation);
    return navigation;
  }

  public getCurrentNavigation(): Widget {
    return this.navigations[this.navigations.length - 1];
  }

  public resetNavigation() {
    const navigation = null;
    this.navigations = [];
    this.$currentNavigation.next(navigation);
  }

  public hasBack(): boolean {
    return this.navigations.length > 1;
  }

  public toggleMinimize() {
    this.isMinimized = !this.isMinimized;
  }

  public getOutputSubscriptions(): IWindowOutput {
    return {
      $output: this.$output,
      $close: this.$close,
      $error: this.$error,
      $minimize: this.$minimize,
      $maximize: this.$maximize,
    };
  }

  public isDetached(): boolean {
    return true;
  }


  public async close(data?: any) {

    this.$close.next(data);
    this.$close.complete();
    this.$error.complete();
    this.$output.complete();
    this.$minimize.complete();
    this.$maximize.complete();
    this.$position.complete();
    this.resetNavigation();

  }

  public output(data?: any) {
    this.$output.next(data);
  }
}
