import { Component, Input, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicMenu } from '../dynamic-menu/dynamic-menu.model';
import { Widget, WidgetState } from '../model/Widget';
import { IWindowPositionEvent, Window } from '../window-manager/window/window.model';

@Component({
  selector: 'ya-widget',
  templateUrl: './widget.component.html',
})
export class WidgetComponent {
  @Input() protected parent: DynamicMenu | Window;
  private instanceRef: Widget = <Widget>{};
  protected state: WidgetState;

  constructor() {
    this.parent = <DynamicMenu>{};
  }

  setNavigation(component: Type<WidgetComponent>, data?: any) {
    this.parent.setNavigation(component, data);
  }

  setInstanceRef(instanceRef: Widget) {
    this.instanceRef = instanceRef;
    this.state = this.instanceRef.state;
  }

  backNavigation() {
    this.parent.backNavigation();
  }

  hasBack(): boolean {
    return this.parent.hasBack();
  }

  output(data: unknown) {
    (this.parent as Window).output(data);
  }

  close(data?: unknown) {
    this.state.yaScrollPos = 0;
    (this.parent as Window).close(data);
  }

  onMaximize(): Observable<unknown> | undefined {
    if (this.parent instanceof Window) {
      return this.parent?.$maximize?.asObservable();
    }
  }

  onMinimize(): Observable<unknown> | undefined {
    if (this.parent instanceof Window) {
      return this.parent?.$minimize?.asObservable();
    }
  }

  onClose(): Observable<unknown> | undefined {
    if (this.parent instanceof Window) {
      return this.parent?.$close?.asObservable();
    }
  }

  highlight(isHighlight: boolean) {
    if (this.parent instanceof Window) {
      this.parent.isHighlight = isHighlight;
    }
  }


  isDetached(): boolean {
    return this.parent.isDetached();
  }

  onPosition(): Observable<IWindowPositionEvent> | undefined {
    if (this.parent instanceof Window) {
      return this.parent.$position.asObservable();
    }
  }

  isWindowReady(): boolean {
    if (this.parent instanceof Window) {
      return this.parent.isReady;
    }
    return true;
  }
}
