import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    canLoad: [AuthGuard],
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'dashboard',
    canLoad: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'srd',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./situation-room-display/situation-room-display.module').then((m) => m.SituationRoomDisplayModule),
  },
  {
    path: 'ship-database',
    canLoad: [AuthGuard],
    loadChildren: () => import('./ship-database/ship-database.module').then((m) => m.ShipDatabaseModule),
  },
  {
    path: 'data-source',
    canLoad: [AuthGuard],
    loadChildren: () => import('./data-source/data-source.module').then((m) => m.DataSourceModule),
  },
  {
    path: 'screen-capture',
    canLoad: [AuthGuard],
    loadChildren: () => import('./screen-capture/screen-capture.module').then((m) => m.ScreenCaptureModule)
  },
  {
    path: 'denied',
    loadChildren: () => import('./access-denied/access-denied.module').then((m) => m.AccessDeniedModule),
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
