import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import * as Domain from '../../domain';

@Injectable({
  providedIn: 'root',
})
export class DataApiService {
  constructor(private http: HttpClient) {}

  listTags(type: string) {
    let headers = new HttpHeaders();
    headers = headers.append('type', type);
    return this.http
      .get<Domain.RequestResponse>(`/light/api/tag`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Tags[]));
  }

  listMsaIcons(filtered?: string) {
    let headers = new HttpHeaders();
    if (filtered) headers = headers.append('filtered', filtered);
    return this.http
      .get<Domain.RequestResponse>(`/light/api/icon`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Icons[]));
  }

  listMsaObjectsProperties() {
    const headers = new HttpHeaders();
    return this.http
      .get<Domain.RequestResponse>(`/light/api/layer/properties`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  listMsaObjectsDefaultProperties(property: string) {
    const headers = new HttpHeaders({ property: property });
    return this.http
      .get<Domain.RequestResponse>(`/light/api/layer/defaultProperties`, { headers: headers })
      .pipe(map((res) => res.data));
  }
}
