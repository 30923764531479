import { Injectable } from '@angular/core';
import { Subject, interval, lastValueFrom, race } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { DatabaseWorkerEvent } from './worker/database.types';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  private readonly pushTimeout = 1000 * 30; // 30 seconds

  // send data
  private $dbPush: Subject<DatabaseWorkerEvent> = new Subject<DatabaseWorkerEvent>();
  public dbPush$ = this.$dbPush.asObservable();

  // receive data
  public $dbPull: Subject<DatabaseWorkerEvent> = new Subject<DatabaseWorkerEvent>();
  private dbPull$ = this.$dbPull.asObservable();

  async pushSync<T = any>(event: DatabaseWorkerEvent): Promise<DatabaseWorkerEvent<T>> {
    event.correlationId ??= uuidv4();

    const response$ = this.dbPull$.pipe(
      filter((x) => x.correlationId === event.correlationId),
      //tap((x) => console.log({ x })),
      first(),
    );

    const timeout$ = interval(this.pushTimeout).pipe(
      first(),
      map(() => {
        throw new Error(
          `Failed to receive db response within timeout of ${this.pushTimeout}ms for event "${event.operation}" "${event.entityType}""`,
        );
      }),
    );

    const result = lastValueFrom(race(response$, timeout$));

    this.$dbPush.next(event);

    return result;
  }
}
