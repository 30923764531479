import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as Domain from '../../domain';
import { api_default_limit } from '../api.constants';

@Injectable({
  providedIn: 'root',
})
export class MapObjectFilterApiService {
  constructor(private http: HttpClient) {}

  private readonly api_path = '/light/api/map-object-filter/';

  create(filter: {
    Name: string;
    Rules: {}[];
    ShortcutRules: {}[];
    LogicalQuery: string;
    ShortcutLogicalQuery: string;
    BetweenShortcutAndRules: string;
  }): Observable<Domain.MapObjectFilter> {
    return this.http.post(this.api_path, filter).pipe(map((res) => res as Domain.MapObjectFilter));
  }

  list({ limit = api_default_limit, offset = 0 }: Domain.IPaginatedRequest = {}) {
    const params = new HttpParams().set('limit', String(limit)).set('offset', String(offset));

    return this.http.get<Domain.RequestResponse>(this.api_path, { params: params });
  }

  update(filter: {
    _id: string;
    Name: string;
    Rules: {}[];
    ShortcutRules: {}[];
    LogicalQuery: string;
    ShortcutLogicalQuery: string;
    BetweenShortcutAndRules: string;
  }): Observable<Domain.MapObjectFilter> {
    return this.http.put(this.api_path, filter).pipe(map((res) => res as Domain.MapObjectFilter));
  }

  delete(id: string): Observable<Domain.MapObjectFilter> {
    const params = new HttpParams().append('_id', id);

    return this.http.delete(this.api_path, { params: params }).pipe(map((res) => res as Domain.MapObjectFilter));
  }
}
