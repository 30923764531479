import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SortType } from '../../base/list-component.service.base';
import * as Domain from '../../domain';
import { ChatFilter } from '../../domain';
import { api_default_limit, api_default_page } from '../api.constants';

@Injectable({
  providedIn: 'root',
})
export class ChatApiService {
  constructor(private http: HttpClient) {}

  private readonly chat_api_path = '/light/api/Chat';
  private readonly chat_api_path_v2 = '/light/api/v2/Chat';

  toggleChatOutsideYA(chatId: string): Observable<void> {
    const headers = new HttpHeaders({ chat_id: chatId });
    return this.http.patch<void>(`${this.chat_api_path}`, null, { headers });
  }

  listChats(
    situationId?: string,
    limit: number = 2000,
    offset: number = 0,
    orderBy: { Name?: SortType; Sensitivity?: SortType; Security?: SortType } = {},
    query?: { searchTerm?: string; filter?: ChatFilter },
    getLastMessage: boolean = false,
    isGlobal: boolean = false,
  ): Observable<Domain.Chat[]> {
    let headers = new HttpHeaders({ offset: String(offset), limit: String(limit) });

    if (isGlobal) {
      headers = headers.append('isGlobal', 'true');
    }

    if (situationId) {
      headers = headers.append('situation_id', situationId);
    }

    if (orderBy) {
      (Object.keys(orderBy) as (keyof typeof orderBy)[]).forEach((key) => {
        if (orderBy[key]) {
          headers = headers.append('orderBy', key);
          headers = headers.append('desc', String(orderBy[key] === 'desc'));
        }
      });
    }

    if (query) {
      headers = headers.append('query', JSON.stringify(query));
    }

    if (getLastMessage) {
      headers = headers.append('getLastMessage', 'true');
    }

    return this.http
      .get<Domain.RequestResponse>(`${this.chat_api_path_v2}`, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.Chat[]));
  }

  getChat(id: string, situationId?: string, visibility?: string): Observable<Domain.Chat | undefined> {
    let headers = new HttpHeaders({
      id: id,
      limit: String(api_default_limit),
      page: String(api_default_page),
    });

    if (visibility) {
      headers = headers.append('show', visibility);
    }
    if (situationId) {
      headers = headers.append('situation_id', situationId);
    }

    return this.http.get<Domain.RequestResponse>(`${this.chat_api_path}`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return;
        }
        return res.data.docs[0] as Domain.Chat;
      }),
    );
  }

  createChat(chat: {
    Name: string;
    Security: Domain.SecurityType;
    SensitivityLevel: Domain.SensitivityLevel;
    Situation_id: string;
    Users_ids: string[];
    MSAObject_id?: string;
    MSAObject_info?: string;
    ShareWithOutsideYA?: boolean;
    OpenToAnyone: boolean;
  }): Observable<Domain.Chat> {
    return this.http
      .post<Domain.RequestResponse>(`${this.chat_api_path}`, chat)
      .pipe(map((res) => res.data as Domain.Chat));
  }

  updateChat(chat: any): Observable<Domain.Chat> {
    return this.http
      .put<Domain.RequestResponse>(`${this.chat_api_path}`, chat)
      .pipe(map((res) => res.data as Domain.Chat));
  }

  updateGeneralChat(situation_id: string, groups: string[]): Observable<Domain.Chat> {
    const headers = new HttpHeaders();
    headers.append('situation_id', situation_id);
    headers.append('groups', groups);

    return this.http.put<Domain.RequestResponse>(`${this.chat_api_path}/general`, { headers }).pipe(
      map((res) => {
        return res.data as Domain.Chat;
      }),
    );
  }

  updateChatFavorite(chat: Domain.Chat, isFavorite: boolean): Observable<Domain.Chat> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.chat_api_path}/favorite`, { _id: chat._id, isFavorite: isFavorite })
      .pipe(map((res) => res.data as Domain.Chat));
  }

  updateChatVisibilityToUser(chat_id: string, state: string): Observable<Domain.Chat> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.chat_api_path}/visibility`, { chat_id: chat_id, state: state })
      .pipe(map((res) => res.data as Domain.Chat));
  }

  listChatParts(
    chatId?: string,
    limit: number = api_default_limit,
    page: number = api_default_page,
    fetchChatCreatedMessage: boolean = false,
  ): Observable<Domain.ChatPart[]> {
    let headers = new HttpHeaders({ offset: String(page), limit: String(limit) });
    if (chatId) {
      headers = headers.append('chat_id', chatId);
    }

    if (fetchChatCreatedMessage) {
      headers = headers.append('fetchChatCreatedMessage', 'true');
    }

    return this.http
      .get<Domain.RequestResponse>(`${this.chat_api_path}Part`, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.ChatPart[]));
  }

  getChatParts(
    chatId?: string,
    offset: number = 0,
    limit: number = api_default_limit,
    page: number = api_default_page,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({ page: String(page), limit: String(limit), offset: String(offset) });
    if (chatId) {
      headers = headers.append('chat_id', chatId);
    }
    return this.http.get<Domain.RequestResponse>(`${this.chat_api_path}Part`, { headers: headers });
  }

  listChatPartDefaultMessages(property?: string) {
    const headers = new HttpHeaders({ ...(property ? { visible: property } : {}) });
    return this.http
      .get<Domain.RequestResponse>(`${this.chat_api_path}Part/defaultMessages`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  createDefaultMessage(message: Domain.MsaDefaultMessage): Observable<Domain.ChatPartDefaultMessage> {
    return this.http
      .post<Domain.RequestResponse>(`${this.chat_api_path}Part/defaultMessages`, message)
      .pipe(map((res) => res.data as Domain.ChatPartDefaultMessage));
  }

  updateDefaultMessageVisibility(message: any): Observable<Domain.ChatPartDefaultMessage> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.chat_api_path}Part/defaultVisibility`, message)
      .pipe(map((res) => res.data));
  }
  deleteDefaultMessage(message: any): Observable<Domain.ChatPartDefaultMessage> {
    const headers = new HttpHeaders({ id: message._id });
    return this.http
      .delete<Domain.RequestResponse>(`${this.chat_api_path}Part/defaultMessages`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  updateDefaultMessage(message: Domain.MsaDefaultMessage): Observable<Domain.ChatPartDefaultMessage> {
    return this.http
      .put<Domain.RequestResponse>(`${this.chat_api_path}Part/defaultMessages`, message)
      .pipe(map((res) => res.data as Domain.ChatPartDefaultMessage));
  }

  getExerciseTag() {
    return this.http
      .get<Domain.RequestResponse>(`${this.chat_api_path}Part/exerciceTag`)
      .pipe(map((res: any) => res.data as string));
  }

  exportChat(room_id: string) {
    return this.http.get(`${this.chat_api_path}/exportChat`, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/zip').append('room_id', room_id),
    });
  }

  getUnreadChatPartsCount(situationsId?: string[], chatsId?: string[]): Observable<Domain.UnreadCountSummary[]> {
    let params = new HttpParams();

    if (situationsId?.length)
      for (const id of situationsId) {
        params = params.append('situationsId[]', id);
      }

    if (chatsId?.length)
      for (const id of chatsId) {
        params = params.append('chatsId[]', id);
      }

    if (!situationsId?.length && !chatsId?.length) params = params.append('isGlobal', true);

    return this.http
      .get('/light/api/readMark/unreadChatPartsCount', { params })
      .pipe(map((res) => res as Domain.UnreadCountSummary[]));
  }

  getUnreadChatPartsCountByTab(situationId?: string): Observable<Domain.UnreadCountSummaryByTab> {
    let params = new HttpParams();

    if (situationId) params = params.append('situationId', situationId);

    return this.http
      .get('/light/api/readMark/unreadChatPartCountByTab', { params })
      .pipe(map((res) => res as Domain.UnreadCountSummaryByTab));
  }

  createUsersAddedOrRemovedChatPart(
    chatId: string,
    senderId: string,
    usersIdsBefore: string[],
    usersIdsAfter: string[],
  ) {
    const params: any = {};

    if (chatId) {
      params.chatId = chatId;
    }

    if (senderId) {
      params.senderId = senderId;
    }

    if (usersIdsBefore?.length) {
      let usersIds: string[] = [];

      for (const userId of usersIdsBefore) {
        usersIds = [...usersIds, userId];
      }

      params.usersIdsBefore = usersIds;
    }

    if (usersIdsAfter?.length) {
      let usersIds: string[] = [];

      for (const userId of usersIdsAfter) {
        usersIds = [...usersIds, userId];
      }

      params.usersIdsAfter = usersIds;
    }

    return this.http.post(`${this.chat_api_path}Part/createChatPartsForUsersAddedOrRemoved`, { params });
  }
}
