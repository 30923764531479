import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Domain from '../../domain';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SensorApiService {
    constructor(private http: HttpClient) { }

    private readonly sensor_api_path = '/light/api/sensor';

    updateDefaultPropertyOwner({
        property,
        vesselId,
        newVesselSensorOwner,
        oldVesselSensorOwner,
    }: {
        property: string,
        vesselId: string,
        newVesselSensorOwner: string,
        oldVesselSensorOwner: string,
    }) {
        return this.http.patch<Domain.RequestResponse>(`${this.sensor_api_path}/default-owner`, {
            Property: String(property),
            VesselId: String(vesselId),
            NewVesselSensorOwner: String(newVesselSensorOwner),
            OldVesselSensorOwner: oldVesselSensorOwner ? String(oldVesselSensorOwner) : null
        });
    }

    updateSensorVesselTarget({
        sensorVesselId,
        vesselId
    }: {
        sensorVesselId: string,
        vesselId: string,
    }) {
        return this.http.patch<Domain.RequestResponse>(`${this.sensor_api_path}/vessel`, {
            SensorVesselId: String(sensorVesselId),
            VesselId: String(vesselId),
        });
    }

    list({
        offset,
        limit,
        status,
        search
    }: {
        offset?: number;
        limit?: number;
        status?: string;
        search?: string;
    } = {}): Observable<Domain.RequestListResponse> {
        let params = new HttpParams().append('offset', String(offset)).append('limit', String(limit));

        if (status) {
            params = params.append('status', status);
        }

        if (search) {
            params = params.append('search', search);
        }

        return this.http.get<Domain.RequestListResponse<Domain.VesselResponse>>(`${this.sensor_api_path}/data`, { params });
    }
}
