import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IWindowOutput, Window } from './window/window.model';

@Injectable({
  providedIn: 'root',
})
export class WindowManagerService {
  private windows: Window[];
  $windows: Subject<Window[]>;
  private currentIndex: number;

  constructor() {
    this.windows = [];
    this.$windows = new BehaviorSubject<Window[]>([]);
    this.currentIndex = 300;
  }

  open(window: Window, offset?: { x: number; y: number }, reopen = false): IWindowOutput {
    const currentWindow = this.windows.find((w) => window.title === w.title);

    if (currentWindow) {
      if (reopen) {
        this.closeWindow(currentWindow)
      } else {
        currentWindow.zIndex = this.currentIndex++;
        return window.getOutputSubscriptions();
      }
    }

    window.xPos = 20 * this.windows.length;
    window.yPos = 20 * this.windows.length;
    window.offsetX = offset?.x;
    window.offsetY = offset?.y;
    window.zIndex = this.currentIndex++;

    this.windows.push(window);
    this.$windows.next(this.windows);

    window.$close.subscribe({
      complete: () => this.closeWindow(window)
    });

    return window.getOutputSubscriptions();
  }

  closeWindow(window: Window) {
    if (!window) {
      return;
    }
    window.isReady = false;
    setTimeout(() => {
      const indexToRemove = this.windows.indexOf(window);
      if (indexToRemove == -1) {
        return;
      }
      this.windows.splice(indexToRemove, 1);
      this.$windows.next(this.windows);
    }, 500);

  }

  hasWindow(title: string) {
    const hasWindow = this.windows.filter((w) => w.title === title);
    return !!hasWindow?.length;
  }

  closeWindowByTitle(title: string) {
    const window = this.windows.find((w) => w.title == title);
    this.closeWindow(window);
  }

  focusWindow(window: Window) {
    window.zIndex = ++this.currentIndex;
  }

  closeAllWindows(): void {
    this.windows.forEach(window => {
      window.close();
    });
  }
}
