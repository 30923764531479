import { Component, OnInit } from '@angular/core';
import { DynamicMenu } from 'src/app/components/dynamic-menu/dynamic-menu.model';
import { WidgetComponent } from 'src/app/components/widget/widget.component';
import { DebugConsoleLogsComponent } from '../debug-console-logs/debug-console-logs.component';
import { DebugConsoleTroubleshootComponent } from '../debug-console-troubleshoot/debug-console-troubleshoot.component';
import { DebugCollectorService } from 'src/app/core/utils/debug-collector.service';
import { UsageService } from 'src/app/core/utils/usage.service';

@Component({
  selector: 'ya-debug-console-navigation',
  templateUrl: './debug-console-navigation.component.html',
  styleUrls: ['./debug-console-navigation.component.scss']
})
export class DebugConsoleNavigationComponent extends WidgetComponent implements OnInit {
  currentMenu: DynamicMenu | undefined;
  fields = [
    'Troubleshoot',
    'Logs',
  ];
  constructor(
    private readonly dcs: DebugCollectorService,
    private usageService: UsageService,
  ) {
    super();
  }
  selected: string;

  ngOnInit(): void {
    this.usageService.captureDebugConsoleUsage('open', 'open_window')
    this.handleSelect(this.fields[0])
  }

  handleSelect(selection: string): void {
    this.selected = selection;
    this.currentMenu = null;
    setTimeout(() => {

      switch (selection) {
        case 'Troubleshoot':
          this.currentMenu = new DynamicMenu('Troubleshoot', '', DebugConsoleTroubleshootComponent);
          break;
        case 'Logs':
          this.currentMenu = new DynamicMenu('Logs', '', DebugConsoleLogsComponent);
          break;
        default:
          this.currentMenu = null;
      }
    }, 50);
  }

  ngOnDestroy(){
    this.usageService.captureDebugConsoleUsage('close', 'close_window')
  }

  protected handleDownload() {
    this.usageService.captureDebugConsoleUsage('download', 'download_object')
    this.dcs.downloadReport();
  }
}
