import { Injectable } from '@angular/core';
import { DatabaseService } from '../database.service';
import { DatabaseRepositoryBase } from '../database.repository.base';
import { UsageEntityType } from '../worker/database.types';

@Injectable({
  providedIn: 'root',
})
export class UsageRepository extends DatabaseRepositoryBase<UsageEntityType> {
  constructor(databaseService: DatabaseService) {
    super('usage', databaseService);
  }
}
