import { WorkerEvents } from 'src/app/core/utils/worker/worker-events';
import { WorkerMessenger } from 'src/app/core/utils/worker/worker-messenger';

export class WorkerMessengerService {
  private _worker: Worker = null;
  private _workerMessenger: WorkerMessenger = null;

  private _workerEvents: WorkerEvents = null;

  protected get worker() {
    return this._worker;
  }

  get workerMessenger() {
    return this._workerMessenger;
  }

  initWorker(worker: Worker) {
    if (!this.worker) {
      this._worker = worker;
      this._workerMessenger = new WorkerMessenger(this.worker);
      this._workerEvents = new WorkerEvents(this.worker);
    }
  }

  async postMessageSync(data: any): Promise<any> {
    return this.workerMessenger.postMessageSync(data);
  }

  protected events() {
    return this._workerEvents.events();
  }
}
