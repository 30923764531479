<div class="icon" [ngSwitch]="highlight" [class.centered]="!title" *ngIf="hasIcon">
  <ya-icon *ngSwitchCase="'success'" [size]="18" [strokeWidth]="3" class="icon">ya-editing-check-mark</ya-icon>
  <ya-icon *ngSwitchCase="'danger'" [size]="18" [strokeWidth]="3" class="icon">ya-editing-remove</ya-icon>
  <ya-icon *ngSwitchCase="'info'" [size]="18" [strokeWidth]="2" class="icon">ya-alerts-feedback-info</ya-icon>
  <ya-icon *ngSwitchCase="'warning'" [size]="18" [strokeWidth]="2" class="icon">ya-alerts-feedback-warning</ya-icon>
  <ya-icon *ngSwitchCase="'important'" [size]="18" [strokeWidth]="2" class="icon">ya-editing-danger</ya-icon>
  <ya-icon *ngSwitchCase="'standard'" [size]="18" [strokeWidth]="2" class="icon">ya-alerts-feedback-info</ya-icon>
</div>

<div class="body" *ngIf="!dynamicComponent">
  <ya-toast-title *ngIf="title">{{ title }}</ya-toast-title>
  <ya-toast-message *ngIf="message">{{ message }}</ya-toast-message>
  <ng-content select="ya-toast-title" *ngIf="!(dynamicComponent && title)"></ng-content>
  <ng-content select="ya-toast-message" *ngIf="!(dynamicComponent && message)"></ng-content>
</div>

<ng-template dynamicToastComponent></ng-template>
<ya-toast-actions *ngIf="hasAction" (click)="handleAction()">
  <ya-button color="bright" highlighted="true" [class.no-border]="!actionText" [disabled]="isLoading">
    <ya-icon [size]="16" [strokeWidth]="2" class="action">{{ actionIcon ? actionIcon : 'ya-editing-remove' }}</ya-icon>
    {{ actionText }}
  </ya-button>
</ya-toast-actions>
