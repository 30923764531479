import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import * as Domain from '../../domain';

@Injectable({
  providedIn: 'root',
})
export class FolderApiService {
  constructor(private http: HttpClient) { }

  private readonly folder_api_path = '/light/api/folder';

  listFolders(folderType: Domain.FolderType): Observable<Domain.Folder[]> {
    const headers = new HttpHeaders({ type: String(folderType) });
    return this.http
      .get<Domain.RequestResponse>(`${this.folder_api_path}`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Folder[]));
  }

  listRootFolders(folderType: Domain.FolderType): Observable<Domain.Folder[]> {
    const headers = new HttpHeaders({ type: String(folderType) });
    return this.http
      .get<Domain.RequestResponse>(`${this.folder_api_path}/root`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Folder[]));
  }

  listSubFolders(folderId: string, folderType: Domain.FolderType): Observable<Domain.Folder[]> {
    const headers = new HttpHeaders({ id: String(folderId), type: String(folderType) });
    return this.http
      .get<Domain.RequestResponse>(`${this.folder_api_path}/subFolders`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Folder[]));
  }

  createFolder(folder: Pick<Domain.Folder, 'Name' | 'Type' | 'Parent' | 'Owner' | 'IsTransversal'>): Observable<Domain.Folder> {
    return this.http
      .post<Domain.RequestResponse>(`${this.folder_api_path}`, folder)
      .pipe(map((res) => res.data as Domain.Folder));
  }

  deleteFolder(folder: Domain.Folder): Observable<Domain.Folder> {
    const headers = new HttpHeaders({ id: folder._id });

    return this.http
      .delete<Domain.RequestResponse>(`${this.folder_api_path}`, { headers: headers })
      .pipe(map((res) => res.data.doc as Domain.Folder));
  }

  updateFolder(folder: Domain.Folder): Observable<Domain.Folder> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.folder_api_path}`, folder)
      .pipe(map((res) => res.data as Domain.Folder));
  }

  getFolder(folderId: string): Observable<Domain.Folder> {
    const headers = new HttpHeaders({ id: String(folderId) });
    return this.http
      .get<Domain.RequestResponse>(`${this.folder_api_path}`, { headers })
      .pipe(map((res) => res.data?.[0] as Domain.Folder));
  }
}
