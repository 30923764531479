import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClusterService } from '../utils/cluster.service';

@Injectable()
export class ClusterHeaderHttpInterceptorService implements HttpInterceptor {
  constructor(private clusterService: ClusterService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clusterBasedEndpoints = ['/light/api/layer'];

    const shouldAddClusterHeader = clusterBasedEndpoints.some((endpoint) => req.url.includes(endpoint));

    if (!shouldAddClusterHeader) {
      return next.handle(req);
    }

    const clonedRequest = req.clone({
      setHeaders: {
        cluster: this.clusterService.getInitialCluster(),
      },
    });

    return next.handle(clonedRequest);
  }
}
