import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiError, ErrorManagerService } from '../utils/error-manager.service';
import { DebugCollectorService, ECategory } from '../utils/debug-collector.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorManager: ErrorManagerService,
    private readonly dcs: DebugCollectorService
    ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((requestError) => {

        if (requestError.status >= 400) {
          let { error } = requestError;
          if (!error) {
            error = {}
          }
          error.status = requestError.status;
          this.dcs.error(error, ECategory.API);
          if (error instanceof Blob) {
            this.parseBlobError(error).subscribe((parsedError) => {
              this.errorManager.pushErrorToManager(parsedError);
            });
          } else this.errorManager.pushErrorToManager(error);
        }
        return throwError(() => requestError);
      }),
    );
  }

  private parseBlobError(error: Blob): Observable<ApiError> {
    return from(error.text()).pipe(map((text: string) => JSON.parse(text) as ApiError));
  }
}
