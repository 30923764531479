import { filter, first, interval, lastValueFrom, map, race, Subject, takeUntil } from 'rxjs';
import { v4 as uuid } from 'uuid';

interface WorkerMessage {
  id: string;
  data: any;
}

export class WorkerMessenger {
  private push$ = new Subject<WorkerMessage>();
  private pull$ = new Subject<WorkerMessage>();
  private destroy$ = new Subject<void>();

  private pushTimeout;
  private onMessage;

  constructor(private worker: Worker, pushTimeout = 1000 * 30) {
    this.pushTimeout = pushTimeout;

    this.push$.pipe(takeUntil(this.destroy$)).subscribe((data) => worker.postMessage(data));

    this.onMessage = this.handleMessage.bind(this);
    this.worker.addEventListener('message', this.onMessage);
  }

  private handleMessage(message: MessageEvent<any>) {
    if (message.data?.id) this.pull$.next(message.data);
  }

  destroy() {
    this.destroy$.next();
    this.worker.removeEventListener('message', this.onMessage);
  }

  async postMessageSync(data: any): Promise<any> {
    const id = uuid();

    const response$ = this.pull$.pipe(
      takeUntil(this.destroy$),
      filter((event) => event.id === id),
      first(),
      map((e) => e.data),
    );

    const timeout$ = interval(this.pushTimeout).pipe(
      first(),
      map(() => {
        throw new Error(`Failed to receive sync response within timeout of ${this.pushTimeout}ms for event "${data}"`);
      }),
    );

    const result = lastValueFrom(race(response$, timeout$).pipe(takeUntil(this.destroy$)));

    this.push$.next({ id, data });

    return result;
  }
}
