import { HostListener, Injectable, OnDestroy } from '@angular/core';
import { BroadcastChannelType } from '../domain';
import { SocketService } from '../socket/socket.service';

import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, distinctUntilChanged } from 'rxjs';
import { filter } from 'rxjs/operators';
import TabContextsNames from 'src/app/core/utils/TabContextsNames';
import { v4 as uuidv4 } from 'uuid';

interface BroadcastMessage {
  type?: string;
  payload: any;
}

interface TabsStorage {
  [tabContext: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class TabsService implements OnDestroy {
  readonly OPEN_SOCKETS_LIMIT = 10;
  readonly TABS_STORAGE_KEY = 'tabs';
  openSockets = 0;
  bc = new BroadcastChannel('HMI');

  get tabContext() {
    return window.name;
  }

  private broadcastChannel: BroadcastChannel = new BroadcastChannel('hmi');
  private onMessage = new Subject<any>();

  constructor(private socketService: SocketService, private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.saveThisTabToStorage());

    this.setListeners();

    this.broadcastChannel.onmessage = (message) => this.onMessage.next(message.data);
  }

  setCurrentTabContext(context: TabContextsNames) {
    this.removeThisTabFromStorage();

    let finalContext: string = context;

    switch (context) {
      case TabContextsNames.ShipDatabase:
        finalContext = finalContext + uuidv4();
        break;
      case TabContextsNames.Dashboard:
      default:
        break;
    }

    window.name = finalContext;

    this.saveThisTabToStorage();
  }

  private saveThisTabToStorage() {
    if (!this.tabContext) return;

    const tabs: TabsStorage = this.getTabsFromStorage();
    tabs[this.tabContext] = this.router.url;
    localStorage.setItem(this.TABS_STORAGE_KEY, JSON.stringify(tabs));
  }

  private getTabsFromStorage(): TabsStorage {
    return JSON.parse(localStorage.getItem(this.TABS_STORAGE_KEY) || '{}');
  }

  private removeThisTabFromStorage() {
    if (!this.tabContext) return;

    const tabs: TabsStorage = this.getTabsFromStorage();
    delete tabs[this.tabContext];
    localStorage.setItem(this.TABS_STORAGE_KEY, JSON.stringify(tabs));
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.removeThisTabFromStorage();
  }

  onLogout() {
    localStorage.removeItem(this.TABS_STORAGE_KEY);
  }

  publish(message: BroadcastMessage): void {
    this.onMessage.next(message);
  }

  messagesOfType(type: string): Observable<BroadcastMessage> {
    return this.onMessage.pipe(filter((message) => message.type === type));
  }

  getViewOnMapMessages() {
    return this.onMessage.pipe(filter((message) => message.type === BroadcastChannelType.ViewOnMap));
  }

  getLanguageChanges() {
    return this.onMessage.pipe(filter((message) => message.type === BroadcastChannelType.Language));
  }

  getPageStatusMessages() {
    return this.onMessage.pipe(filter((message) => message.type === BroadcastChannelType.PageStatus));
  }

  postMessage(broadcastMessage: BroadcastMessage) {
    this.broadcastChannel.postMessage(broadcastMessage);
  }

  viewOnMapShipDatabase(broadcastMessage: BroadcastMessage) {
    this.broadcastChannel.postMessage(
      Object.assign(broadcastMessage, {
        type: BroadcastChannelType.ViewOnMap,
      }),
    );
  }

  referencePage(broadcastMessage: BroadcastMessage) {
    this.broadcastChannel.postMessage(
      Object.assign(broadcastMessage, {
        type: BroadcastChannelType.PageStatus,
      }),
    );
  }

  languageChangeEvent(broadcastMessage: BroadcastMessage) {
    this.broadcastChannel.postMessage(
      Object.assign(broadcastMessage, {
        type: BroadcastChannelType.Language,
      }),
    );
  }

  setListeners() {
    this.socketService
      .getCounter()
      .pipe(distinctUntilChanged())
      .subscribe((socketsOpen: number) => {
        this.openSockets = socketsOpen;
      });
  }

  private findUrlInOpenedTabs(url: string) {
    const tabs = this.getTabsFromStorage();
    const contextFound = Object.keys(tabs).find((tabContext) => tabs[tabContext].includes(url));
    return contextFound ? { context: contextFound, url: tabs[contextFound] } : undefined;
  }

  private openTab(url: string) {
    const tab = this.findUrlInOpenedTabs(url);
    if (tab) {
      const winRef = window.open('', tab.context);

      if (winRef.location.href === 'about:blank') {
        winRef.location.href = url;
      }
    } else {
      window.open(`/${url}`, '_blank');
    }

    //The maximum number of opened windows is 10 corresponding to 1xMSA + 9x shipdataset
    if (this.openSockets >= this.OPEN_SOCKETS_LIMIT) this.errorModal();
  }

  openShipdatabase(search?: string) {
    const url = 'ship-database/' + (search ? `information?search=${search}` : '');
    this.openTab(url);
  }

  redirectToShipDatabase() {
    const url = 'ship-database';
    this.openTab(url);
  }

  redirectToSensorData() {
    const url = 'data-source';
    this.openTab(url);
  }

  errorModal() {
    console.error('Max number of tabs exceded');
  }
}
