import { Component, Input, OnInit } from '@angular/core';
import { WidgetComponent } from 'src/app/components/widget/widget.component';
import { DebugCollectorService, ETestStatus, Test } from 'src/app/core/utils/debug-collector.service';


@Component({
  selector: 'ya-debug-console-troubleshoot',
  templateUrl: './debug-console-troubleshoot.component.html',
  styleUrls: ['../debug-console.scss']
})
export class DebugConsoleTroubleshootComponent extends WidgetComponent implements OnInit {
  @Input() isEmbedded = false;

  protected ETestStatus = ETestStatus;

  tests: Test[] = [];

  constructor(
    private readonly dcs: DebugCollectorService
  ) {
    super();
  }

  async ngOnInit() {
    this.tests = await this.dcs.runTests();
  }
}
