import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { FeatureConfig } from '../domain';

export enum FeatureFlags {
  AutomaticTranslation = 'AutomaticTranslation',
  ScreenshotAllElements = 'ScreenshotAllElements',
  UserPredefinedSites = 'UserPredefinedSites',
  TooltipShowAllInformations = 'TooltipShowAllInformations',
  ChangeMetricUnits = 'ChangeMetricUnits',
  DebugConsoleFlag = 'DebugConsoleFlag',
  UtcMoreVisible = 'UtcMoreVisible',
  ImageMapObject = 'ImageMapObject',
  DirexLoudSpeaker = 'DirexLoudSpeaker',
  Tasks = 'Tasks',
  SituationRoomDisplay = 'SituationRoomDisplay',
  ShowMapLoadingErrors = 'ShowMapLoadingErrors',
  ThirdPartyLayerWMS = 'ThirdPartyLayerWMS',
  NewLogDesign = 'NewLogDesign',
  StaticLayerUrl = 'StaticLayerUrl',
  ConnectMapObjectToWindow = 'ConnectMapObjectToWindow',
  DataSource = 'DataSource',
  DataSourcePage = 'DataSourcePage',
  Replay = 'Replay',
  ShipDatabaseSelectOnMap = 'ShipDatabaseSelectOnMap',
  ManualValuesDrawing = 'ManualValuesOnDrawing',
  ShipDatabaseExternalLinks = 'ShipDatabaseExternalLinks',
  ScreenRecording = 'ScreenRecording',
  EventDecisionAlerts = 'EventDecisionAlerts'
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  config: FeatureConfig = null;

  constructor(private readonly apiService: ApiService) { }

  loadConfig() {
    return this.apiService.userGroup.getDefaultParams().pipe(tap((data) => (this.config = data.FeatureFlag)));
  }

  isFeatureEnabled(key: FeatureFlags) {
    if (this.config && key in this.config) {
      return this.config[key];
    }
    return false;
  }
}
