<div class="panel-title" (click)="toggleCollapse()" [class.collapsed]="collapsed">
  <div class="left" *ngIf="highlight != 'normal'"></div>
  <ng-content select="ya-expansion-panel-title"></ng-content>
  <ng-content select="ya-expansion-panel-subtitle"></ng-content>
  <ya-icon [size]="14" [strokeWidth]="1" *ngIf="!collapsed && !!this.collapsible "> {{ collapsedIcon }} </ya-icon>
  <ya-icon [size]="14" [strokeWidth]="1" *ngIf="collapsed && !!this.collapsible "> {{ expandedIcon }} </ya-icon>
</div>

<div class="panel-body" [class.collapsed]="collapsed">
  <ng-content select="ya-expansion-panel-body"></ng-content>
</div>
