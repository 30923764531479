import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as Domain from '../../domain';
import { Event, EventLocation } from '../../domain';
import { api_default_limit, api_default_page } from '../api.constants';

@Injectable({
  providedIn: 'root',
})
export class LogApiService {
  constructor(private http: HttpClient) {}

  private readonly log_api_path = '/light/api/Event';
  private readonly comment_api_path = '/light/api/Comment';

  listEvents(
    situationId?: string,
    history = false,
    offset: number = 0,
    limit: number = api_default_limit,
    desc = true,
    query?: string,
    fetchDefaultEvents?: boolean,
  ): Observable<Domain.RequestListResponse<Event>> {
    let headers = new HttpHeaders({ limit: String(limit), offset: String(offset) });
    if (situationId) {
      headers = headers.append('situation_id', situationId);
    }

    if (desc) {
      headers = headers.append('desc', String(desc));
    }

    if (history) {
      headers = headers.append('history', String(history));
    }

    if (query) {
      headers = headers.append('query', query);
    }

    if (typeof fetchDefaultEvents === 'boolean') {
      headers = headers.append('fetchDefaultEvents', fetchDefaultEvents.toString());
    }

    return this.http.get<Domain.RequestResponse>(`${this.log_api_path}/EventWithHistories`, { headers: headers });
  }

  getEvent(id: string, situation_id: string, bring_event_in_any_way = false): Observable<Domain.Event | undefined> {
    const headers = new HttpHeaders({
      id: id,
      limit: String(api_default_limit),
      page: String(api_default_page),
      situation_id: String(situation_id),
      history: String(true),
      bring_event_in_any_way: String(bring_event_in_any_way),
    });
    return this.http.get<Domain.RequestResponse>(`${this.log_api_path}`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return undefined;
        }
        return res.data.docs[0] as Domain.Event;
      }),
    );
  }

  createEvent(event: {
    Security: Domain.SecurityType;
    SensitivityLevel: Domain.SensitivityLevel;
    Situation_id: string;
    EventType: Domain.EventType;
    Description?: string;
    Tags?: string[];
    fileURL?: string;
    MSAObject_id?: string;
    MSAObject_info?: string;
    EventCategory?: Domain.EventCategory;
    IncidentType?: string;
    ShareWithOutsideYA?: boolean;
    Content?: string;
    Contents?: File[];
  }): Observable<Domain.Event> {
    return this.http
      .post<Domain.RequestResponse>(`${this.log_api_path}`, event)
      .pipe(map((res) => res.data as Domain.Event));
  }

  toggleEventOutsideYA(eventId: string): Observable<void> {
    const headers = new HttpHeaders({ event_id: eventId });
    return this.http.patch<void>(`${this.log_api_path}/toggleReleaseOutsideYA`, null, { headers });
  }

  toggleCommentOutsideYA(eventId: string, commentId: string): Observable<void> {
    const headers = new HttpHeaders({ event_id: eventId, comment_id: commentId });
    return this.http.patch<void>(`${this.comment_api_path}`, null, { headers });
  }

  deleteIncident(id: string): Observable<Domain.EventLocation> {
    const headers = new HttpHeaders({ id: id });
    return this.http
      .delete<Domain.RequestResponse>(`${this.log_api_path}/location`, { headers: headers })
      .pipe(map((res) => res.data as Domain.EventLocation));
  }

  listIncidents(offset = 0, limit = api_default_limit, searchTerm?: string, cluster?:string) {
    let params = new HttpParams().set('limit', String(limit)).set('offset', String(offset));
    if (searchTerm) {
      params = params.append('searchTerm', searchTerm);
    }
    if(cluster) {
      params = params.append('cluster', cluster);
    }
    return this.http.get<Domain.EventLocation[]>(`${this.log_api_path}/location/incidents`, { params: params });
  }

  listIncidentWarnings() {
    const headers = new HttpHeaders();
    return this.http.get<Domain.EventLocation[]>(`${this.log_api_path}/location/incidentwarnings`, {
      headers: headers,
    });
  }

  logExport(situation_id: string, includeEventsHistory: boolean = true) {
    const includeEventsHistoryString = includeEventsHistory.toString();
    return this.http.get(`${this.log_api_path}/exportPDF`, {
      responseType: 'blob',
      headers: new HttpHeaders()
        .append('Content-Type', 'application/pdf')
        .append('situation_id', situation_id)
        .append('include-events-history-string', includeEventsHistoryString),
    });
  }

  createEventLocation(eventLocation: EventLocation) {
    return this.http.post<Domain.RequestResponse>(`${this.log_api_path}/location`, eventLocation);
  }

  updateEventLocation(eventLocation: EventLocation) {
    return this.http.put<Domain.EventLocation>(`${this.log_api_path}/location`, eventLocation);
  }

  listComments(
    eventId?: string,
    search?: string,
    offset: number = 0,
    limit: number = api_default_limit,
    page: number = api_default_page,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({ page: String(page), limit: String(limit), offset: String(offset) }); //implementar infinity scroll
    if (eventId) {
      headers = headers.append('event_id', eventId);
    }

    if (search) {
      headers = headers.append('search', search);
    }
    return this.http.get<Domain.RequestResponse>(`${this.comment_api_path}`, { headers: headers });
  }

  listCommentsHistories(
    eventId?: string,
    search?: string,
    offset: number = 0,
    limit: number = api_default_limit,
    page: number = api_default_page,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({ page: String(page), limit: String(limit), offset: String(offset) }); //implementar infinity scroll
    if (eventId) {
      headers = headers.append('event_id', eventId);

      if (search) {
        headers = headers.append('search', search);
      }
    }
    return this.http.get<Domain.RequestResponse>(`${this.comment_api_path}/ListHistories`, { headers: headers });
  }

  getComments(commentId: string, event_id: string): Observable<Domain.Comment | undefined> {
    const headers = new HttpHeaders({
      id: String(commentId),
      event_id: String(event_id),
    });
    return this.http.get<Domain.RequestResponse>(`${this.comment_api_path}`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return undefined;
        }
        return res.data.docs[0] as Domain.Comment;
      }),
    );
  }

  createComment(comment: {
    Text: string;
    Security: Domain.SecurityType;
    SensitivityLevel: Domain.SensitivityLevel;
    Event_id: string;
    ShareWithOutsideYA: boolean;
    IsHistory: boolean;
  }): Observable<Domain.Comment> {
    return this.http
      .post<Domain.RequestResponse>(`${this.comment_api_path}`, comment)
      .pipe(map((res) => res.data as Domain.Comment));
  }

  getUnreadEventsCount(situationsId: string[]): Observable<Domain.UnreadCountSummary[]> {
    let params = new HttpParams();

    for (const id of situationsId) {
      params = params.append('situationsId[]', id);
    }

    return this.http
      .get('/light/api/readMark/unreadEventsCount', { params })
      .pipe(map((res) => res as Domain.UnreadCountSummary[]));
  }

  getUnreadLogsCommentsCount(logIds?: string[]): Observable<Domain.UnreadCountSummary[]> {
    let params = new HttpParams();

    if (logIds?.length)
      for (const id of logIds) {
        params = params.append('logIds[]', id);
      }

    return this.http
      .get('/light/api/readMark/unreadLogsCommentsCount', { params })
      .pipe(map((res) => res as Domain.UnreadCountSummary[]));
  }
}
