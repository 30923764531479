import { Injectable } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { MSAObject, Replay, ReplayChunk } from 'src/app/core/domain';
import {
  AddChunkMessage,
  GetObjectsAtTimeMessage,
  IsChunkProcessedMessage,
  ReplayWorkerEvent,
} from 'src/app/core/utils/worker/replay/replay-worker.types';
import { WorkerMessengerService } from 'src/app/core/utils/worker/worker-messenger.service';

@Injectable({
  providedIn: 'root',
})
export class ReplayWorkerMessengerService extends WorkerMessengerService {
  chunkUnloaded(): Observable<ReplayWorkerEvent> {
    return this.events().pipe(filter((event) => event.event === 'unloadedChunk')) as Observable<ReplayWorkerEvent>;
  }

  async isChunkProcessed(layerId: string, chunk: ReplayChunk): Promise<boolean> {
    const message: IsChunkProcessedMessage = {
      operation: 'isChunkProcessed',
      data: {
        layerId,
        chunk,
      },
    };
    return this.postMessageSync(message);
  }

  async sendChunk(replay: Replay, layerId: string, chunk: ReplayChunk, objects: MSAObject[]) {
    const message: AddChunkMessage = {
      operation: 'addChunk',
      data: {
        layerId,
        chunksDefinition: replay.Chunks,
        chunk: chunk,
        objects,
      },
    };
    await this.postMessageSync(message);
  }

  async getObjectsAtTime(layerId: string, date: string): Promise<{ [id: string]: MSAObject }> {
    const message: GetObjectsAtTimeMessage = {
      operation: 'getObjectsAtTime',
      data: {
        layerId,
        date,
      },
    };
    return this.postMessageSync(message);
  }
}
