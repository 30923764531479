import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as Domain from '../../domain';
import { BoardMessage } from '../../domain';
import { api_default_limit, api_default_page } from '../api.constants';

@Injectable({
  providedIn: 'root',
})
export class MessageBoardApiService {
  constructor(private http: HttpClient) {}

  private readonly messageboard_api_path = '/light/api/boardMessage';

  shareBoardMessage(
    boardMessage_id: string,
    sharingGroups: Domain.SharingGroup[],
    sharingGroupRules: Domain.SharingGroupRule[],
  ): Observable<Domain.BoardMessage> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.messageboard_api_path}/share`, {
        _id: boardMessage_id,
        SharingGroups: sharingGroups,
        SharingGroupRules: sharingGroupRules,
      })
      .pipe(map((res) => res.data as Domain.BoardMessage));
  }

  createBoardMessage(boardMessage: {
    SensitivityLevel?: Domain.SensitivityLevel;
    Security?: Domain.SecurityType;
    ShareWithOutsideYA?: boolean;
    Message?: string;
    StartsAt?: string;
    ExpiresAt?: string;
  }): Observable<Domain.BoardMessage> {
    return this.http
      .post(`${this.messageboard_api_path}/`, boardMessage)
      .pipe(map((res) => res as Domain.BoardMessage));
  }

  deleteBoardMessage(boardMessageId: string): Observable<Domain.BoardMessage> {
    const params = new HttpParams().append('_id', boardMessageId);

    return this.http
      .delete(`${this.messageboard_api_path}`, { params: params })
      .pipe(map((res) => res as Domain.BoardMessage));
  }

  updateBoardMessage(boardMessage: {
    _id: string;
    SensitivityLevel?: Domain.SensitivityLevel;
    Security?: Domain.SecurityType;
    ShareWithOutsideYA?: boolean;
    Message?: string;
    StartsAt?: string;
    ExpiresAt?: string;
  }): Observable<Domain.BoardMessage> {
    return this.http.put(`${this.messageboard_api_path}`, boardMessage).pipe(map((res) => res as Domain.BoardMessage));
  }

  listBoardMessages(offset = 0, limit = api_default_limit, boardMessageFilter: { Message?: any } = {}) {
    let params = new HttpParams().set('limit', String(limit)).set('offset', String(offset));

    if (boardMessageFilter?.Message) {
      params = params.set('ContextList', boardMessageFilter.Message.context.list);
      params = params.set('SearchTerm', boardMessageFilter.Message.search);
    }

    return this.http.get<Domain.RequestListResponse<BoardMessage>>(`${this.messageboard_api_path}`, { params: params });
  }
}
