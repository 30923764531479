import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuditApiService {
  constructor(private http: HttpClient) {}

  private readonly audit = '/light/api/audit'
  list({
    offset,
    limit,
    targetsFatherId,
    targetId,
    filter,
    search,
  }: {
    offset?: number;
    limit?: number;
    targetsFatherId?: string;
    targetId?: string;
    summedUp?: boolean;
    filter?: string;
    search?: string;
  } = {}): Observable<any> {
    let params = new HttpParams();
    if (targetsFatherId) params = params.append('targetsFatherId', String(targetsFatherId));
    if (targetId) params = params.append('targetId', String(targetId));
    if (offset) params = params.append('offset', String(offset));
    if (limit) params = params.append('limit', String(limit));
    if (filter) params = params.append('filter', String(filter));
    if (search != undefined) params = params.append('search', String(search));

    return this.http.get<any>(`${this.audit}`, { params });
  }
}
